<template>
    <div class="dashboard" v-if="load">
        <div class="column">
          <div class="block left">
            <div class="block-title">{{region}} rankings</div>
            <div class="round-circle">#1</div>
          </div>
          <div class="block middle">
            <div class="block-title">Level</div>
            <div>
              <img width="35px" :title="data.level.level" :src="data.level.level_image"/>
            </div>
          </div>
          <div class="block right">
            <div class="block-title">Question Asked</div>
            <div>
                <div class="round-circle">{{data.questioncount}}</div>
            </div>
          </div>
        </div>
        <div class="column mt-5">
          <div class="block left">
             <div class="block-title">played</div>
             <div><router-link :to="{name: 'questionAttempt'}">{{data.totalAttend}}</router-link></div>
          </div>
          <div class="block middle">
             <div class="block-title">this week</div>
             <div>50</div>
          </div>
          <div class="block right">
            <div class="block-title">score</div>
            <div>40</div>
          </div>
        </div>
      </div>
   
</template>
<script>
export default {
 name: 'Userprogress',
 data(){
    return{
       data:[],
       load:false
    }
 },
 mounted(){
      this.getDashboarddata();
 },
 computed:
 {
    region() {
        return this.$store.getters.getRegion;
    },
 },
 methods:
 {
    getDashboarddata()
    {
      this.$store.dispatch("getDashboardData").then((response) => { 
        this.data = response.data; 
        this.load = true;
      });
    }
 }
}    
</script>
<style scoped>
.column{display: flex; }
.column .block{flex: 0 0 33%; text-align: center;}
.column .block-title{font-size: 18px; text-transform: uppercase; font-weight: 500; margin-bottom: 5px;}
.round-circle{width: 60px; height: 60px; line-height: 60px; background-color: rgb(255 115 37); border-radius: 50%; margin: auto; color: black;}
.showquestion{width: 80%; margin: auto; margin-top: 5px; box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; padding: 15px; min-height: 60px; border-radius: 10px;}
.showcounter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>