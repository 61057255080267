<template>
 <v-container class="fill-height" fluid>
  <v-row align="center" justify="center">
   <v-col cols="12" sm="6" md="6" >
    <v-card class="customround">
     <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="8" class="text-center pt-5 pb-5">
       <v-form action="#" @submit.prevent="submit">
        <v-card-text class="removespace">
          <div class="red--text" v-if="error">{{ error }}</div>
          <v-text-field ref="oldpassword" type="password" v-model="oldpass" label="Current Password" :rules="[required]" outlined />
          <v-text-field ref="password" type="password" v-model="pass" label="New Password" data-vv-name="pass" :rules="[required, min6]" outlined />
          <v-text-field v-model="pass2" type="password" label="Confirm Password" data-vv-name="pass" :rules="[required, min6, matchingPasswords ]" outlined />
        </v-card-text>
        <v-card-actions class="justify-center">
              <v-row dense>
                 <v-col col="12" md="12" sm="12">  
                   <v-btn type="submit" :disabled="disabled" class="button__full x-large" color="primary">Change Password</v-btn>
                 </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
            </v-col> 
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>  
  </v-container>
</template>
<script>
 export default{
   name:"personal",
   data(){
     return {
        text: "",
        pass: "",
        pass2: "",
        oldpass:"",
        snackbar: false,
        timeout: 2000,
        error:"",
        disabled:false
     };   
   },  
   methods: {
      required: function(value) {
      if (value) {
        return true;
      } else {
        return 'This field is required.';
      }
    },
    min6: function(value) {
      if (value.length >= 6) {
        return true;
      } else {
        return 'Password should have more than 6 characters.';
      }
    },
    matchingPasswords: function() {
      if (this.pass === this.pass2) {
        return true;
      } else {
        return 'Passwords does not match.';
      }
    },
    submit () 
    {
        if(this.oldpass == "" || this.pass == "" || this.pass2 == "")
        {
           this.error = "Please fill all fields";
           return;
        }

        if(this.oldpass == "")      
        {
           this.error = "Please provide old password";
           return;
        }
        this.disabled = true;
        this.$store.dispatch("changePassword",{password:this.pass,oldpassword:this.oldpass}).then((result) => {
          this.snackbar = true;
          this.disabled = false;
          if(result.data.status == true)
          {
            this.text = result.data.message;
          }
          else
          {
            this.text = result.data.message;
          }
        });
          
    },
   } 
 }
</script>
<style scoped>
.row {margin-left: 0px;}
.customround.v-card:not(.v-sheet--tile):not(.v-card--shaped){border-radius:20px;}
.button__full{width:98%;height:45px !important;}
.removespace{padding-bottom:0;}
</style>