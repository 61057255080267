<template>
  <div class="fill-height">
    <div class="container mt-10 mb-10 align-items-center mobdiff">
      <Userprogress />
      <div class="action-time mt-10">
          <div class="text-center">
            <div class="mt-5"><v-btn  to="/category" color="primary">Answer a question</v-btn></div>
            <div class="mt-5"><v-btn @click="checkaction" color="secondary">Add a new Question</v-btn></div>
            <div class="mt-5" v-if="electionmanager > 0"><v-btn  to="/election-dashboard" class="white--text"  color="#666666">Election Dashboard</v-btn></div>
          </div>
      </div>

      <div class="questionlisting mt-8 mb-4">
          <h3 class="text-center">Question Asked</h3>
          <Userquestion />
      </div>
    </div>  
    <v-dialog transition="dialog-bottom-transition" max-width="300" v-model="showdialog">
          <v-card>
            <v-toolbar color="primary" dark>Alert</v-toolbar>
            <v-card-text>
              <div class="mt-5 message">{{ dialogmsg }}</div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="closedialog()">Close</v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>   
    
  </div>
</template>
<script>
  import Userquestion from '../components/Userquestion.vue';
  import Userprogress from '../components/Userprogress.vue'

  export default {
    name: 'Dashboard',
    data(){
      return{
         data:[],
         reacheddailylimit:0,
         showdialog:false,
         dialogmsg:'',
         electionmanager:0
      }
    },
    components:{
      Userquestion,
      Userprogress
    },
    created(){
       this.$store.dispatch("checkManager").then((response) => {
         this.electionmanager = response.data;
       });
    },
    methods:{
      checkaction(){
        //to="/add-question"
        if(this.reacheddailylimit == 0)
        {
          this.$router.push("/add-question");
        }
        else
        {
          this.showdialog = true;
          this.dialogmsg  = "Your daily limit for 3 question is reached";
        }   
      },
      
    }
  }
</script>    
<style scoped>
.column{display: flex; }
.column .block{flex: 0 0 33%; text-align: center;}
.column .block-title{font-size: 18px; text-transform: uppercase; font-weight: 500; margin-bottom: 5px;}
.round-circle{width: 60px; height: 60px; line-height: 60px; background-color: rgb(255 115 37); border-radius: 50%; margin: auto; color: black;}
.showquestion{width: 80%; margin: auto; margin-top: 5px; box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; padding: 15px; min-height: 60px; border-radius: 10px;}
.showcounter,.actbtnsdash {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
<style>
.toplogo{position: relative;}
.back-button{position: absolute;    top: 12%;}
.dashboard{width: 80%; margin:auto; box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; padding: 15px; min-height: 100px; border-radius: 10px; }
@media(max-width:768px)
{
  .dashboard{width: 100%;}
  .mobdiff{margin-top: 25px !important; margin-bottom: 5px !important;}
}    
</style>