<template>
 <div :style="customStyles" ref="childContent">
  <div v-if="capturedImage" class="imageprev">
    <div class="tempnamespan">{{ previewdata.template_name }}</div>
    <img :src="capturedImage" style="margin-bottom: 10px;" />
  </div>
    <div class="main-container-template" :style="{ backgroundColor: color }" v-if="preview" ref="captureArea">
        <div class="top-logos-with-heading">
          <div class="logos-block">
            <div class="block-1">
              <img v-if="!showresult" src="../assets/sample-logo.png" />
              <img v-if="showresult && previewdata['data']['headerlogo1']" :src="convertimage(previewdata['data']['headerlogo1'])" />
            </div>
            <div class="block-2">
               &nbsp;
            </div>
            <div class="block-3">
              <img v-if="!showresult" src="../assets/sample-logo.png" />
              <img v-if="showresult && previewdata['data']['headerlogo2']" :src="convertimage(previewdata['data']['headerlogo2'])" />
            </div>
          </div>
          <div class="stamp-heading">
             <div class="first-heading">Ballot Paper</div>
             <div class="second-heading">
                <span v-if="!showresult">Election Heading Goes Here</span>
                <span v-if="showresult && previewdata['data']['topheading']">{{ previewdata['data']['topheading'] }}</span>
             </div>
             <div class="placename">
                <span v-if="!showresult">Election division of</span>
                <span v-if="showresult && previewdata['data']['heading2']">{{ previewdata['data']['heading2'] }}</span>
             </div>
             <div class="election-name">
                <span v-if="!showresult">Division Name</span>
                <span v-if="showresult && previewdata['data']['heading3']">{{ previewdata['data']['heading3'] }}</span> 
             </div>  
          </div>
        </div>
        <div class="inner-part">
          <div class="first-part">
            <div class="inner-heading">
              <span v-if="!showresult">Number of the boxes from 1 to 8 in the order of your choice</span>
              <span v-if="showresult && previewdata['data'].heading3">
                Number of the boxes from 1 to {{ getLength(previewdata.candidates) }} in the order of your choice
             </span>
            </div>
            <div class="participants">
              <template v-if="!showresult"> 
                <div class="participant-list" v-for="number in range(5)" :key="number">
                  <div class="party-check">&nbsp;</div>
                  <div class="party-name">
                    <div class="candidate-name">Candidate Name</div>
                    <div class="candidate-party">Party Name</div>
                  </div>
                </div>
              </template> 
              <template v-if="showresult">
                <div class="participant-list"  v-for="(val,key) in previewdata.candidates" :key="key">
                  <div class="party-check">
                    <div v-if="vote">
                      <span class="clickable" @click="dovote(votetype,val.cid)">
                        <canvas :ref="`canvas_${val.cid}`"  class="canvas"></canvas>
                      </span>
                    </div>
                    <div v-else>&nbsp;</div>
                 </div>
                  <div class="party-name">
                    <div class="candidate-name">{{val.lastname}} {{val.firstname}}</div>
                    <div class="candidate-party">{{val.designation}}</div>
                  </div>
                </div>
              </template>  
            </div>
            <div class="note-div">
              <span v-if="!showresult">Remember...number <span>every</span> box to make your vote count</span>
              <span v-if="showresult">{{ previewdata['note'] }}</span>
            </div>
          </div>
          <div class="bottom-logo" style="display: none;">
             <img src="../assets/sample-logo.png" />
          </div>
        </div>
      </div>
      <div v-if="fields">
        <div class="myrow">
          <div class="mycolumn">Logo 1</div>
          <div class="mycolumn">
             <input type="file"  v-if="!dataDisable.headerlogo1"  @change="handleImageChange('headerlogo1', $event)" />
             <img class="smalldemoimg" v-else :src=data.headerlogo1 />
             <div class="errorclass" v-if="error.headerlogo1">{{ error.headerlogo1 }}</div>
          </div>
        </div>
        <div class="myrow">
          <div class="mycolumn">Logo 2</div>
          <div class="mycolumn">
            <input type="file" v-if="!dataDisable.headerlogo2" @change="handleImageChange('headerlogo2', $event)" />
            <img class="smalldemoimg" v-else :src=data.headerlogo2 />
          </div>
       </div>
      
        <div class="myrow">
          <div class="mycolumn">Top Heading</div>
          <div class="mycolumn"><v-text-field :disabled="dataDisable.topheading" outlined v-model="data.topheading"  hide-details ></v-text-field></div>
       </div>
       <!--
       <div class="myrow">
          <div class="mycolumn">Heading 1</div>
          <div class="mycolumn"><v-text-field :disabled="dataDisable.heading2" outlined v-model="data.heading2"  hide-details ></v-text-field></div>
       </div>
       
       <div class="myrow">
           <div class="mycolumn">Heading 2</div>
           <div class="mycolumn">
            <v-text-field  outlined :disabled="dataDisable.heading3" v-model="data.heading3"  hide-details ></v-text-field>
               
           </div>
          </div>
          -->
       </div>
 </div>
</template>
<script>
import html2canvas from 'html2canvas';
export default {
  props:{
    customStyles: {
      type: Object,
      default: () => ({}),
    },
    preview:{
        type:Boolean,
        default:false
    },
    fields:{
        type:Boolean,
        default:false
    },
    showresult:{
        type:Boolean,
        default:false
    },
    previewdata: {
      type: Array,
      default: () => []
    },
    makedisable:{
        type:Boolean,
        default:false
    },
    vote:{
        type:Boolean,
        default:false
    },
    votetype:{
       type:String,
       default:''
    },
    showimage:{
       type:Boolean,
       default:false
    }
  },  
  data() {
    return {
      imageUrl: null,
      showsource:true,
      data:{'headerlogo1':'','topheading':'','headerlogo2':'','heading2':'','heading3':''},
      dataDisable:{'headerlogo1':false,'topheading':false,'headerlogo2':false,'heading2':false,'heading3':false},
      error:{'heading3':''},
      votefor:[],
      numberOfVotes:[],
      usedNumber:[],
      capturedImage: null,
    };
  },
  mounted() {
    if(this.showimage)
  {
    this.captureImage();
  }
    if(typeof this.previewdata.data != 'undefined')
    {
       this.data = this.previewdata.data;
       Object.keys(this.previewdata.data).forEach(key => {
        if(this.previewdata.data[key] == null)
        {
           this.dataDisable[key] = false;
        }
        else
        {
          if(this.previewdata.data[key] != ''  && this.makedisable)
             this.dataDisable[key] = true;
          else
             this.dataDisable[key] = false;
        }
      });
      if(this.votetype == 'Number')
      {
         for(let i = 1; i <= this.previewdata.candidates.length; i++)
         {
            this.numberOfVotes.push(i);
         }
      }
    } 
    else
    {
      Object.keys(this.data).forEach(key => {
        
           this.dataDisable[key] = false;
        
      });
    }
    this.$emit('child-mounted', this.$refs.childContent);
  },
  computed:{
    color(){
       var retcolor = '#EEF6E7';
       
       if(typeof this.previewdata['color'] != 'undefined')
       {
        if(this.previewdata['color'] != '')
       {
         retcolor = this.previewdata['color'];
       }
       }

       return retcolor;
    }
  },
  methods: {
    async captureImage() {
      try {
        const captureArea = this.$refs.captureArea;
        
        // Capture the content of the div using html2canvas
        const canvas = await html2canvas(captureArea, {
          useCORS: true, // Enable CORS for external images
          allowTaint: true, // Allow cross-origin images
        });
        
        // Convert canvas to Data URL
        this.capturedImage = canvas.toDataURL('image/png');

        // Hide the original HTML content
        this.$refs.captureArea.style.display = 'none';
      } catch (error) {
        console.error('Error capturing image:', error);
      }
    },
    convertimage(file){
      
      if (this.isBlob(file)) {
        return URL.createObjectURL(file);
      } else if (this.isValidUrl(file)) {
        return file;
      } else {
        return '';
      }
      
    },
    isBlob(object) {
      return object instanceof Blob;
    },
    isValidUrl(string) {
      try {
        new URL(string);
        return true;
      } catch (_) {
        return false;
      }
    },
    dovote(votetype,cid){
       if(votetype == 'Number')
       {
         const keyPresent = this.votefor.some(obj => Object.prototype.hasOwnProperty.call(obj, cid));
       
         if(!keyPresent) 
         {
           let minKey;
           let minValue = Infinity;

           for (const [key, value] of Object.entries(this.numberOfVotes)) 
           {
              if (value < minValue) {
                minKey = key;
                minValue = value;
              }
           } 
           let votenumber = minValue;
           this.numberOfVotes.splice(minKey,1);
           this.usedNumber.push(votenumber);
           this.votefor.push({ [cid]: votenumber });
           this.drawNumberImage(cid,votenumber);
         } 
         else
         {
             let key      = this.findPositionOfKey(cid);
             let innerkey = Object.keys(this.votefor[key]);
             let findval  = this.votefor[key][innerkey];
             this.numberOfVotes.push(findval);
            
             let obj     = this.usedNumber;
             let keys  = Object.keys(obj);
             let index = keys.findIndex(key => obj[key] === findval);
             this.usedNumber.splice(index,1);
             
             this.votefor.splice(key,1);
             this.clearCanvas(cid);
         }
         if(this.numberOfVotes.length == 0)
         {
           this.$emit('data-sent', { showconfirm: 1, vote: this.votefor });  
         }  
       }
       else if(votetype == 'Color')
       {
          if(this.votefor.length > 0)
          {
            let keys = Object.keys(this.votefor[0]);   
            this.clearCanvas(keys[0]);
            this.votefor = [];
          }
          this.votefor.push({ [cid]: 1 });
          this.fillBackground(cid);
          if(this.votefor.length == 1)
            this.$emit('data-sent', { showconfirm: 1, vote: this.votefor });  
          else
            this.$emit('data-sent', { showconfirm: 0 });  
       }
       else if(votetype == 'Cross')
       {
          if(this.votefor.length > 0)
          {
            let keys = Object.keys(this.votefor[0]);   
            this.clearCanvas(keys[0]);
            this.votefor = [];
          }
          this.votefor.push({ [cid]: 1 });
          this.fillCross(cid);
          if(this.votefor.length == 1)
            this.$emit('data-sent', { showconfirm: 1, vote: this.votefor });  
          else
            this.$emit('data-sent', { showconfirm: 0 });  
       }
    },
    findPositionOfKey(keyToFind) 
    {
      let array = this.votefor;
      for (let i = 0; i < array.length; i++) 
      {
        let obj = array[i];
        if (Object.prototype.hasOwnProperty.call(obj, keyToFind)) {
            return i; // Return the index if the key is found
        }
      }
      return -1; // Return -1 if the key is not found
    },
    drawNumberImage(index,selectedNumber) 
    {
      const canvasRef = this.$refs[`canvas_${index}`][0];
      canvasRef.classList.add('eraseit');
        if (canvasRef && canvasRef.getContext) 
        {
            const ctx = canvasRef.getContext('2d');
            ctx.clearRect(0, 0, canvasRef.width, canvasRef.height);
            ctx.font = '100px Arial';
            ctx.fillText(selectedNumber, 50, 100);
        }
    },
    clearCanvas(canvasref) {
          const canvas = this.$refs['canvas_'+canvasref][0];
          canvas.classList.remove('eraseit');
          const ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height);
    },   
    fillBackground(index){
      const canvasRef = this.$refs[`canvas_${index}`][0];
      canvasRef.classList.add('remleft');
       if (canvasRef && canvasRef.getContext) 
       {
           const ctx = canvasRef.getContext('2d');
           ctx.fillStyle = 'black'; 
           ctx.fillRect(0, 0, canvasRef.width, canvasRef.height); 
       }
    },
    fillCross(index){
      const canvasRef = this.$refs[`canvas_${index}`][0];
      canvasRef.classList.add('remleft');
       if (canvasRef && canvasRef.getContext) 
       {
          const ctx = canvasRef.getContext('2d');
          ctx.strokeStyle = 'black'; // Set line color
          ctx.lineWidth = 10; // Set line width

          ctx.beginPath();
          ctx.moveTo(0, 0);
          ctx.lineTo(canvasRef.width, canvasRef.height);
          ctx.stroke();

          ctx.beginPath();
          ctx.moveTo(canvasRef.width, 0);
          ctx.lineTo(0, canvasRef.height);
          ctx.stroke();
       }
    },
    getLength(data){
      if(typeof data != 'undefined')
      {
         return data.length;
      }
      return 5;
    },
    range(limit) {
      return Array.from({ length: limit }, (_, index) => index + 1);
    },
    sendDataToParent() {
      const data = this.data;
      if(data.headerlogo1 == '' || typeof data.headerlogo1 == 'undefined')
      {
         this.error.headerlogo1 = 'Please provide election logo';
         this.$root.$refs.Electiondashboard.gotoTop();
         return;
      }
      else
      {
        this.$emit('child-data', data);
      } 
    },
    handleImageChange(index, event) {
      const file = event.target.files[0];
      this.data[index] = file;
     /* const reader = new FileReader();

      reader.onload = () => {
        this.data[index] = reader.result;
      };

      if (file) {
        reader.readAsDataURL(file);
      }*/
    },
  }
};
</script>
<style scoped>
.main-container-template{height: 100%; max-width: 420px;   margin: auto;margin: auto; padding: 15px; background: #EEF6E7;}
.top-logos-with-heading {position: relative; margin-bottom: 0;}
.top-logos-with-heading > .logos-block{display: flex; align-items: center; justify-content: space-between;}
.placename{font-size: 14px;}
.election-name{font-weight: bold; font-size: 16px;}
.line-break{border: 1px solid black;}
.inner-heading{font-size: 16px;font-weight: bold;    text-align: center;}
.participant-list{display: flex; align-items: center; margin-top: 5px;}
.participant-list > div{margin-right: 10px;}
.party-logo{width: 20%;}
.party-check{width: 15%; border: 1px solid #000; height: 40px;}
.participants{margin-bottom: 1%;}
.custom-hr{border-top: 3px solid #000;}
.logos-block img{ width: 85%; margin: auto;}
.logos-block > div{text-align: center;}
.note-div{font-size: 18px;   text-align: center;    font-weight: bold;}
.note-div span{font-weight: bold; border-bottom: 1px solid #000;}
.stamp-heading {top: 64%;text-align: center;left: 11%;}
div.first-heading,div.second-heading{font-weight: bold; text-transform: uppercase; font-size: 16px;}
.placename{text-transform: uppercase; }
.election-name{text-transform: uppercase;}
.inner-part {
    padding: 10px;
    border: 2px solid #484444;
    position: relative;
    margin-bottom: 0;
}
.bottom-logo {
    position: absolute;
    bottom: -9%;
    left: 32%;
    text-align: center;
    width: 118px;
    height: 60px;
}
.bottom-logo img {
    width: 30%;
}
.block-1{flex: 20%;}
.block-2{flex: 58%; margin-right: 1%; margin-left: 1%; text-align: center;}
.block-3{flex: 20%;}
.myrow{display: flex; margin-bottom: 15px; align-items: center;}
.mycolumn:nth-child(1){flex: 0 0 40%; margin-right: 2%; font-weight: bold;}
.mycolumn:nth-child(2){flex: 0 0 75%;}
.note-div{font-size: 13px;}
.block-1 img, .block-3 img {
  width: 100%;
  max-height: 70px;
}
.candidate-name {
  font-size: 15px;
}
.candidate-party {
  font-size: 13px;
}
.party-check > div,.clickable{display: block; width: 100%;height: inherit; position: relative;}
.canvas {position: absolute; top: 0; left: 10px;  width: 100%;  height: 100%; cursor: url(http://icons.iconarchive.com/icons/designcontest/vintage/32/Pencil-icon.png) 0 30, progress;}
.canvas.remleft{left: 0; }
.canvas.eraseit{cursor: url(http://icons.iconarchive.com/icons/designcontest/vintage/32/Eraser-icon.png) 0 30, progress;}
@media(max-width: 430px) { 
  .main-container-template{  max-width: 430px;}
}  
@media(max-width: 420px){
  .main-container-template{  max-width: 420px;}
}
</style>