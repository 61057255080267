<template>
    <div class="popup-container" @click.self="closePopup">
      <div class="popup" @click.stop>
        <div class="popup-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Popup",
    methods: {
      closePopup() {
        this.$emit("close");
      },
    },
  };
  </script>
  
  <style scoped>
  .popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
  }
  
  .popup {
    
    padding: 20px;
    border-radius: 5px;
  }
  
  .popup-content {
    /* Add your own styles for popup content */
  }
  </style>
  