<template>
    <header id="header" class="header fixed-top" data-scrollto-offset="0">
    <div class="container-fluid d-flex align-items-center justify-content-between">
      <router-link :to="{name:'home'}" class="logo d-flex align-items-center scrollto me-auto me-lg-0">
        <img src="../assets/logo.png" alt=""> 
        <h1>myvoco</h1>
      </router-link>
      <nav id="navbar" class="navbar">
        <ul>
          <li class="nav-link"><router-link :to="{name:'home'}"><span>Home</span></router-link></li>
          <li class="nav-link"><router-link :to="{name:'about'}"><span>About</span></router-link></li>
          <!--<li><a class="nav-link scrollto" @click="goto('services')">Services</a></li>-->
          <li class="nav-link"><router-link :to="{name:'faq'}"><span>FAQ's</span></router-link></li>
          <li class="nav-link"><router-link :to="{name:'contact'}"><span>Contact</span></router-link></li>
          <li>
            <v-btn v-if="!loggedIn" to="/auth" color="secondary">Sign up/ Sign In</v-btn>
            <v-btn v-if="loggedIn" to="/dashboard" color="secondary">Dashboard</v-btn>
          </li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle d-none"></i>
      </nav><!-- .navbar -->
    </div>
  </header>  
</template>
<script>
export default{
   data(){
    return{
     
    };
   },
   computed:{
    loggedIn() {
      return this.$store.getters.loggedIn;
     },
   },
   created(){
      this.$root.$refs.Header = this;
      window.addEventListener('scroll', this.handleSCroll);
   },
   destroyed () {
      window.removeEventListener('scroll', this.handleSCroll);
   },
   methods:{
      goto(ref){
        if(this.$route.name !='home')
        {
          this.$router.push({ name: "home" ,hash: '#'+ref });
        }

        this.$root.$refs.Home.scrollto(ref);
      },
      handleSCroll () {
        let header = document.querySelector(".header");
        if (window.scrollY > 100 && !header.className.includes('sticked')) {
        header.classList.add('sticked'); 
        } else if (window.scrollY < 100) {
          header.classList.remove('sticked');
        }
      }
    },
}
</script>
<style scoped>
.fixed-top {position: fixed; top: 0; right: 0; left: 0;  z-index: 1030;}
.header { padding: 15px 0;  transition: all 0.5s;  z-index: 99; background: #ffffff;}
.align-items-center { align-items: center!important;}
.header.sticked { background: #ffffff; box-shadow: 0px 2px 20px rgba(72, 86, 100, 0.1);}
.header .logo h1 {font-size: 32px;font-weight: 300;color: #000; font-family: 'Poppins', sans-serif; margin-left: 8px;}
.logo{text-decoration: none;}
.logo img{width: 25%;}
.container-fluid{justify-content: space-between;}
@media (min-width: 1280px) {
  .navbar { padding: 0;  position: relative; }
  .navbar ul {margin: 0;padding: 0;display: flex;list-style: none;align-items: center;}
  .navbar li {position: relative;}
  .navbar>ul>li {white-space: nowrap;  }
  .navbar a,.navbar a:focus {display: flex; align-items: center;justify-content: space-between;padding: 14px 20px;font-family:  'Poppins', sans-serif;font-size: 16px;font-weight: 400; color: rgba(58, 71, 83, 0.7); white-space: nowrap;transition: 0.3s;position: relative; text-decoration: none;}
  .navbar a i,.navbar a:focus i {font-size: 12px; line-height: 0; margin-left: 5px;}
  .navbar>ul>li>a:before { content: ""; position: absolute;width: 100%;height: 2px; bottom: 0; left: 0;background-color: #002F8B;visibility: hidden;transition: all 0.3s ease-in-out 0s;transform: scaleX(0);transition: all 0.3s ease-in-out 0s; }
  .navbar a:hover:before,
  .navbar li:hover>a:before,
  .navbar .active:before { visibility: visible; transform: scaleX(0.7);}
  .navbar a:hover,.navbar .active,.navbar .active:focus,.navbar li:hover>a { color: #002F8B; }
  .navbar .dropdown a:hover:before,.navbar .dropdown:hover>a:before, .navbar .dropdown .active:before {visibility: hidden;}
  .navbar .dropdown a:hover,.navbar .dropdown .active,.navbar .dropdown .active:focus,.navbar .dropdown:hover>a { color: #ffffff; background: #485664; }
  .navbar .dropdown ul {display: block; position: absolute;    left: 0;    top: 100%;    margin: 0;    padding: 0 0 10px 0;    z-index: 99;    opacity: 0;    visibility: hidden;    background: #485664;   transition: 0.3s;  }
  .navbar .dropdown ul li { min-width: 200px;}
  .navbar .dropdown ul a {padding: 10px 20px;font-size: 15px;text-transform: none;font-weight: 400;color: rgba(255, 255, 255, 0.5);}
  .navbar .dropdown ul a i {font-size: 12px;}
  .navbar .dropdown ul a:hover,.navbar .dropdown ul .active,.navbar .dropdown ul .active:hover,.navbar .dropdown ul li:hover>a {color: #ffffff;background: #002F8B;}
  .navbar .dropdown:hover>ul { opacity: 1; visibility: visible; }
  .navbar .megamenu { position: static;}
  .navbar .megamenu ul {right: 0;padding: 10px;display: flex;}
  .navbar .megamenu ul li {flex: 1;}
  .navbar .megamenu ul li a,.navbar .megamenu ul li:hover>a {color: rgba(255, 255, 255, 0.5);background: none; text-decoration: none;}
  .navbar .megamenu ul li a:hover,.navbar .megamenu ul li .active,.navbar .megamenu ul li .active:hover {color: #ffffff;background: #002F8B;}
  .navbar .dropdown .dropdown ul {top: 0;left: calc(100% - 30px); visibility: hidden;}
  .navbar .dropdown .dropdown:hover>ul {opacity: 1;top: 0;left: 100%;visibility: visible;}

}
@media (min-width: 1280px) and (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {left: -90%;}
  .navbar .dropdown .dropdown:hover>ul {left: -100%;}
}

</style>