<template>
  <div></div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("destroyToken").then(() => {
      this.$vuetify.theme.dark = false;
      this.$router.push({ name: "home" });
    });
  }
};
</script>