<template>
  <div class="fill-height">
     <Electionlisting v-if="viewComponent == 'listing'"/>
     <Electionform v-if="viewComponent == 'election-form'"/>
  </div>
</template>
<script>
import Electionlisting from './Electionlisting.vue';
import Electionform    from './Electionform.vue'

export default{
  name: 'Electiondashboard',
  components:{
    Electionlisting,
    Electionform
  },
  data(){
    return{
       viewComponent:''
    }
  },
  watch: {
     $route() {
      this.initialMethod(); 
     }  
  },
  mounted(){
     this.initialMethod(); 
  },
  methods:{
    initialMethod(){
      if(typeof this.$route.params.url == 'undefined')
         this.viewComponent = 'listing';
      else
         this.viewComponent = 'election-form'; 
    }
  }
}  
</script>