<template>
  <div>
    <div v-if="data.length == 0" class="showquestion text-center">No Question Asked Yet</div>
    <div v-else>
      <div class="showquestion" v-for="(val,key) in data" :key="key">
        <div class="displaything">
          <div>Q. {{ val.question }}</div>
          <div class="mt-5 showcounter" v-if="val.timedout == 0">
             <div><flip-countdown :deadline=val.endtime></flip-countdown></div>
             <div>{{ val.totalvotes }} Votes</div>
          </div>
          <div class="mt-5" v-else>
            <div class="actbtnsdash">
              <div><v-btn @click="toggle(key)" class="secondary">Show options</v-btn></div>
              <div>{{ val.totalvotes }} Votes</div>
            </div>
            <div class="options"  v-show="isOpenIndex === key">
              <v-radio-group class="ansgroup" :disabled="disabled[key]" @change="updateanswer(val.id,key)" v-model="answer[key]">
                <v-radio v-for="(vals,key) in val.options" :key="key" color="secondary" :value="key">
                  <template v-slot:label>
                    <div class="makefull">
                      <div>{{ vals }}</div>
                      <div v-if="val.timedout == 1">
                        <v-progress-linear 
                        :color=val.percentagecol[key]
                        rounded
                        :value=val.percentage[key]
                        height="15">
                        <span class="progress-bar-txt">
                        {{ val.percentage[key] }}%
                        </span>
                       </v-progress-linear>
                      </div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
          </div>
        </div>
      </div>
    </div>
     
    <v-dialog transition="dialog-bottom-transition" max-width="300" v-model="confirmdialog">
          <v-card>
            <v-toolbar color="primary" dark>Alert</v-toolbar>
            <v-card-text>
              <div class="mt-5 message">You want to confirm this option ?</div>
            </v-card-text>
            <v-card-actions class="justify-center mt-3">
              <v-btn color="green" class="white--text" @click="updateAnswerOption()">Yes</v-btn>
              <v-btn color="red"   class="white--text" @click="canceloption()">No</v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>  
   </div> 
</template>
<script>
import FlipCountdown from 'vue2-flip-countdown';
export default {
    name: 'Userquestion',
    data(){
      return{
         type:'',
         data:[],
         answer:[],
         confirmdialog:false,
         lastqtnid:'',
         lastkey:'',
         disabled:[],
         isOpenIndex:'-1',
      }
    },
    components:{
        FlipCountdown,
    },
    mounted(){
        if(this.$route.name == "dashboard")
          this.type = "dashboard";
        else
          this.type = "all";

        this.geQuestions();
    },
    methods:{
          geQuestions(){
            this.$store.dispatch("getLatestQuestions",{type:this.type}).then((response) => {
            if(response.data.length > 0)
            {
              for(var i = 0; i < response.data.length; i++)
              {
                let vals = response.data[i];
                this.data.push({
                                id:vals.id, 
                                question:vals.question,
                                timedout:vals.timedout,
                                endtime:vals.endtime,
                                totalvotes:vals.totalvotes,
                                options:vals.options,
                                percentage:vals.percentage,
                                percentagecol:vals.percentagecol
                              });
                              
                if(vals.answered == 1)              
                {
                  this.answer[i] = vals.answer;
                  this.disabled[i] = true;
                } 
                else
                {
                  this.answer[i]   = '';
                  this.disabled[i] = false;
                }

                if(vals.makedisable == 1)
                {
                  this.answer[i]   = '';
                  this.disabled[i] = true;
                } 
              }
            }
            
           
        });
        },
        toggle(index){
          this.isOpenIndex = ( this.isOpenIndex == index ) ? null : index;
        },    
        updateanswer(id,lastkey){
        this.lastqtnid = id;
        this.lastkey   = lastkey;
        this.confirmdialog = true;
      },
      canceloption(){
         delete this.answer[this.lastkey];
         this.confirmdialog = false;
      },
      updateAnswerOption(){
         this.disabled[this.lastkey] = true;
         this.confirmdialog = false;
         this.$store.dispatch("updateAnswer",{question:this.lastqtnid,answer:this.answer[this.lastkey]}).then(() => {
           //console.log(response);
         });
      },
      closedialog(){
        this.showdialog = false;
        this.dialogmsg = '';
        
      },
      }
}    
</script>
<style scoped>
  .showquestion{width: 80%; margin: auto; margin-top: 5px; box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; padding: 15px; min-height: 60px; border-radius: 10px;}
  .showcounter,.actbtnsdash {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  </style>