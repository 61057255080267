<template>
  <section>
    <v-container class="fill-height" fluid>
      <v-row align="center" >
        <v-col cols="12" sm="3" >
           <!-- <img style="border:1px dotted gray" src="../assets/st-banner.png" />-->
        </v-col>
        <v-col cols="12" sm="6" md="6" class="mt-10">
          <v-row align="center" justify="center">
            <v-col cols="12" sm="12" md="12" class="text-center ">
              <div class="actiontab">
                <v-tabs v-model="whichtab"  centered>
                    <v-tab>Sign In</v-tab>
                    <v-tab>Sign Up</v-tab>
                </v-tabs>
                <div v-if="whichtab == 0">
                    <login />
                </div>
                <div v-if="whichtab == 1">
                    <register />
                </div>
              </div>                      
            </v-col>
          </v-row>
        </v-col>  
        <v-col cols="12" sm="3" align="right">
           <!-- <img style="border:1px dotted gray" src="../assets/onevone.png" />-->
        </v-col>
      </v-row>
    </v-container>     
  </section>
</template>
<script>
import Login from '../components/Login';
import Register from '../components/Register';

export default {
    name: 'Auth',
    watch:{
      $route(){
        this.action = this.$route.query.action;
        if(this.action == 'login')
          this.whichtab = 0;
        else if(this.action == 'register')  
          this.whichtab = 1;
      }   
    },
    components:{ 
       login: Login,
       register: Register,
    },
    data(){
      return{
        action:"",     
        whichtab:null, 
       }
    },
    created(){
        this.action = this.$route.query.action;
        if(this.action == 'login')
          this.whichtab = 0;
        else if(this.action == 'register')  
          this.whichtab = 1;
    } 
}    
</script>