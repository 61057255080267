<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="9" md="9" class="text-center pb-5 mt-2">
      <v-toolbar-title class="logheading"></v-toolbar-title>
      <v-form action="#" @submit.prevent="login">
        <v-card-text class="removespace">
          <span v-if="error"><strong class="red--text">{{errorText}}</strong></span>
          <v-text-field label="Email" name="username" outlined :rules="emailRules" type="text" id="username" v-model="username"/>
          <v-text-field id="password" label="Password" name="password" :rules="passwordRule" outlined @click:append="show1 = !show1" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'" v-model="password"/>
          
        </v-card-text>
        <v-card-actions class="justify-center">
         <v-row dense>
           <v-col col="12" md="12" sm="12">  
             <v-btn type="submit" :disabled="disablebtn" class="small " color="secondary">Log In</v-btn>
             <div class="mt-5">
              <router-link class="bluecolor" :to="{name: 'reset-password', params:{  }}" >
                 Forgot Password
              </router-link>
             </div>
            
           </v-col>
        </v-row>
       </v-card-actions>
      </v-form>
     </v-col> 
     <div class="overlaycustom"  v-if="identitiesdialog">
      <div class="dialogcustom">
      <v-card>
        <v-toolbar color="primary" dark>One Time Password</v-toolbar>
        <v-card-text>
           <otp />
        </v-card-text>
        
      </v-card>
    </div>
    </div>
  </v-row>
</template>
<script>
import Otp from '../components/Otp.vue';
export default {
  name: "login",
  data() {
    return {
      identitiesdialog:false,
      lastRoute: null,
      username: '',
      password: '',
      damnnid:'',
      error: false,
      show1: false,
      errorText: '',
      disablebtn:false,
      emailRules: [
        v => !!v || "Please enter your email address",
        v =>
          /.+@.+\..+/.test(v) ||
          "This doesn't appear to be a valid email address"
      ],
      passwordRule: [
        v => v.length >= 6 || "Password must be at least 6 characters"
      ],
      damnnIdRule:[
        v => !!v || "Please enter DamnnId",
      ],
      logo: require("@/assets/logo.png")
    };
  },
  components:{ 
       otp: Otp,
    },
  created(){
    
  },
  methods: {
    openregister(){
       this.$store.commit('setActionModal',true);
       this.$store.commit('setActionType','register');
    },
    login() 
    {
       if(this.username != '' && this.password != '' )
       {
        this.disablebtn = true;
         this.$store
        .dispatch("retrieveToken", {
          username: this.username,
          password: this.password,
          damnnId:this.damnnid
        })
        .then(() => {
          this.$store
            .dispatch("retrieveUserDetails")
            .then((response) => {
              this.disablebtn = false;
              if(typeof response == 'undefined')
              {
                this.error = true;
                this.errorText = 'No user exist';
              }
              else
              {
                if(response.data.data.manager == 1)
                {
                   this.identitiesdialog = true;
                }
                else
                {
                  if(this.$store.getters.getRouteMeta)
                  {
                    this.$router.push(this.$store.getters.getRouteMeta);
                  }
                  else
                  {
                    this.$router.push({ name: "home" });
                  }  
                } 
              }
            })
        })
        .catch(error => {
          this.disablebtn = false;
          this.error = true;
          this.errorText = error.response.data.message;
        });
       }
       else
       {
          this.error = true;
          this.disablebtn = false;
          this.errorText = 'Please enter login details';
       }
    }
  }
};
</script>

<style scoped>
.row {margin-left: 0px;}
.customround.v-card:not(.v-sheet--tile):not(.v-card--shaped){border-radius:20px;}
.button__full{width:98%;height:45px !important;}
.removespace{padding-bottom:0;}
.logheading{font-size:24px;font-weight:600; color: black;}
.overlaycustom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dialogcustom {
  background-color: white;
  
  border-radius: 8px;
  text-align: left;
  width: 350px;
  max-width: 80%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
</style>