<template>
  <div>
    <v-dialog v-model="displayDialog" persistent max-width="300">
     <v-card >
        <v-card-actions >
            <v-progress-linear class="mt-5 mb-5" indeterminate color="secondary darken-2"></v-progress-linear>
        </v-card-actions>  
     </v-card>
    </v-dialog>
  </div> 
</template>
<script>
 export default 
 {
  name:"Loader",
  props:{
     displayDialog:Boolean,
     message:String
  },
  data()
  {
    return{
        defaultMessage:'Loading please wait...'
    }
  },
 } 
</script>