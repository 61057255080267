<template>
  <div class="fill-height">
    <v-layout v-resize="handleResize" column class="formobile">
   <v-main>
    <v-app-bar app color="white" light  v-if="showtopbar">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" /> 
     <div class="toplogo " v-if="!drawer">
      <router-link class="toplogsmall" :to="{name:'home'}">
        <img class="vimg v-imageback" height="45" :src="showlogo" /> 
        <h1>{{logotitle}}</h1>  
      </router-link>  
     </div> 
     <v-spacer></v-spacer>
     <div class="topbalance marginright30 cursor notformobile" v-if="loggedIn" @click="opendeposit">
         <span class="toptitle" >DamnN Bank</span>
         <span class="balance" ><span class="d-flex" v-html="currency(getinbankbalance)"></span></span>
       </div>
       <div class="topbalance marginright30 notformobile" v-if="loggedIn">
         <span class="toptitle">PP Bank</span>
         <span class="balance "><span class="displaycurr d-flex" v-html="currency(getbalance)"></span></span>
       </div>
       <div class="topbalance marginright10 notformobile" v-if="loggedIn">
         <div class="toptitle">Kels</div>
         <span class="balance"><span class="displaycurr" v-html="getkels"></span></span>
       </div>
       <v-btn v-if="!loggedIn" to="/auth?action=login" class="btns" outlined rounded color="white">Login</v-btn>
       <v-btn v-if="!loggedIn" to="/auth?action=register" class="btns" outlined rounded color="white">Register</v-btn>
       <v-avatar class="topavatar" v-if="loggedIn"><img :src="getavatar" ></v-avatar>
       <span class="topname" v-if="loggedIn">{{ name }}</span>
        <v-menu bottom offset-y min-width="200px" v-if="loggedIn">
          <template v-slot:activator="{ on, attrs }">
             <span class="material-icons" v-bind="attrs" v-on="on" >keyboard_arrow_down</span>
          </template>
          <v-list class="topbarmenu">
            <v-list-item><router-link :to="{name: 'personal'}">Personal Information</router-link></v-list-item> 
            <v-list-item><router-link :to="{name: 'changepassword'}">Change Password</router-link></v-list-item> 
            <v-list-item><a @click="opendeposit">Deposit</a></v-list-item> 
            <v-list-item><router-link :to="{name: 'logout'}">Logout</router-link></v-list-item>
         </v-list>
       </v-menu>
    </v-app-bar>
    <v-navigation-drawer  v-model="drawer" app class="sidebarcss"   >
      <div class="sidebarlogo "> 
        <img class="vimg v-imageback" height="45" :src="showlogo" /> 
        <h1>{{logotitle}}</h1>  
      </div>
      <v-list nav dense>
        <v-list >
          <v-list-item class="displayonmob" v-if="loggedIn">
            <div class="topbalance marginright30 " >
               <span class="toptitle" @click="opendeposit">DamnN Bank</span>
               <span class="balance" @click="opendeposit"><span class="displaycurr " v-html="currency(getinbankbalance)"></span></span>
             </div>
             <div class="topbalance marginright30">
               <span class="toptitle">PP Bank</span>
               <span class="balance"><span class="displaycurr " v-html="currency(getbalance)"></span></span>
             </div>
              <div class="topbalance marginright10">
               <div class="toptitle">Kels</div>
               <span class="balance"><span class="displaycurr " v-html="getkels"></span></span>
             </div>
           </v-list-item>
          <v-list-item link :to="{ name: 'home' }"  :class="'/' === $route.path ? 'highlighted' : ''">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="showhomemenu" :to="{ name: 'about' }"  :class="'/about' === $route.path ? 'highlighted' : ''">
            <v-list-item-title>About</v-list-item-title>
          </v-list-item>
          <!--<v-list-item v-if="showhomemenu" @click="headermenu('service')">
            <v-list-item-title>Service</v-list-item-title>
          </v-list-item>-->
          <v-list-item v-if="showhomemenu" :to="{ name: 'faq' }"  :class="'/faq' === $route.path ? 'highlighted' : ''">
            <v-list-item-title>Faq's</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="showhomemenu" :to="{ name: 'contact' }"  :class="'/contact' === $route.path ? 'highlighted' : ''">
            <v-list-item-title>Contact Us</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="loggedIn" :to="{ name: 'questionasked' }" >
            <v-list-item-title>Question Asked</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="loggedIn" :to="{ name: 'questionAttempt' }" >
            <v-list-item-title>Question Played</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="loggedIn" :to="{ name: 'history' }" >
            <v-list-item-title>History</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!loggedIn" class="text-center">
            <v-list-item-title>
              <v-btn v-if="!loggedIn" to="/auth" color="secondary">Sign up/ Sign In</v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="loggedIn" class="text-center">
            <v-list-item-title>
              <v-btn  to="/dashboard" color="primary">Dashboard</v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item  v-if="loggedIn" class="text-center">
            <v-list-item-title> <v-btn class=" x-large" to="/logout" color="secondary">Logout</v-btn></v-list-item-title>
            </v-list-item>
         </v-list>  
       </v-list>  
      </v-navigation-drawer>
     <Header v-if="headershow" />
     <vue-page-transition name="flip-y">
      <router-view :key="$route.name" />
     </vue-page-transition>
     <Footer />
   </v-main>
   <Deposite :dialogdeposit.sync="dialogdeposit" ></Deposite>
  </v-layout>
 </div>
</template>
<script>
import Vue from 'vue'
Vue.mixin({
  methods: {
    currencysymbol: function(){
       var symbol   = "$";
       return symbol;      
    },
    
    currency: function (currency="") {
      var newprice = "";
      var dispaycurrency = "";
      if(this.$store.getters.loggedIn) 
      {
         var symbol   = "$";
         dispaycurrency = parseFloat(currency).toFixed(2);
         newprice = "<div class='cursym'>"+symbol+"</div><div class='currval'>"+dispaycurrency+"</div>";
       } 
       return newprice; 
    },
  }
})
  import { mapGetters } from 'vuex'  
  import Deposite from '../components/Deposite.vue'
  import Header from '../views/Header.vue';
  import Footer from '../views/Footer.vue';

  export default {
    name: 'Master',
    components:{ 
       Deposite,
       Header,
       Footer
    },
    data () {
    return {
      view: {
        topOfPage: true
      },
      drawer:false,
      logo:require('@/assets/logo.png'),
      showlogo :'',
      logotitle: 'myvoco', 
      showtopbar:false,
      dialogwithdraw:false,
      dialogdeposit:false,
      hideallthing:false,
      unsubscribedialog:false,
      packlanguage:'',
      btndisable:false,
      snackbar:false,
      loader:false,
      message:'',
      headershow:false,
      window: {width: 0,height: 0},
      showhomemenu:true
     }
    },
    watch:{
        $route(){
          this.handleResize();
         }   
    },
    created(){
      window.addEventListener('resize', this.handleResize);
      let value = localStorage.getItem('is_reloaded'); 
      if(value)
      {
        let checkval = localStorage.getItem('from'); 
        console.log(checkval);
        localStorage.removeItem('is_reloaded');
        if(checkval == 'Auth')
        {
          localStorage.removeItem('Auth');
          this.$store.dispatch("destroyToken").then(() => { 
            window.location.reload();
          });
        }
      }
      this.handleResize();
      this.$root.$refs.Master = this;
      this.$store.dispatch("retrieveUserDetails");
    },
    computed:
    {
      ...mapGetters({
       popupmodel:'getActionmodal',
       screen:'getActionType',
     }),
     loggedIn() {
      return this.$store.getters.loggedIn;
     },
     getavatar(){
      var avatar = require("@/assets/avatar.png");
      
         if(this.$store.getters.getAvatar != '' ) 
        {
            avatar = this.$store.getters.getAvatar;
        } 
         return avatar;
    },
    name() {
      return this.$store.getters.name;
    },
     getinbankbalance(){
      return this.$store.getters.inbank;
     },
     getbalance() {
      return this.$store.getters.balance;
     },
     getkels(){
      return this.$store.getters.kels;
     }
    },
    methods:{
        headermenu(val)
        {
          this.drawer = false;
          if(this.$route.name !='home')
        {
          this.$router.push({ name: "home" ,hash: '#'+val });
        }
          this.$root.$refs.Home.scrollto(val);
        },
        handleResize() 
        {
           this.window.width = window.innerWidth;
           this.window.height = window.innerHeight;
           this.showtopbar = false;
           const showtopmenuArr = ["dashboard","askquestion","question","category","personal","changepassword","questionAttempt","questionasked","history",'electiondashboard'];
           this.showlogo = this.logo;
           this.logotitle = 'myvoco';
            
          /* if(this.$route.name == "category")
           {
              if(typeof this.$route.params.category != 'undefined')
              {
                 if(this.$route.params.category == 'Sports')
                 {
                   this.showlogo = require('@/assets/logo-sports.png');
                 }
                 if(this.$route.params.category == 'Anything')
                 {
                   this.showlogo = require('@/assets/logo-anything.png');
                 }
                 if(this.$route.params.category == 'Elections')
                 {
                   this.showlogo = require('@/assets/logo-election.png');
                 }
              } 
           }*/

           if(this.$route.name == 'home' || this.$route.name == 'Auth')
          {
             this.headershow = true;
          }
       
           if(this.$route.name == 'dashboard')
           {
             if(this.window.width > 768)
             {
              this.showtopbar = true;
             } 
              
           }
           
           if(this.window.width <= 1024)
           {
             this.headershow = false;
             this.showtopbar = true;
             if(this.$route.name == 'home')
             {
               this.showhomemenu = true;
             }  
           }
           else
           {
             if(showtopmenuArr.includes(this.$route.name))
             {
               this.showtopbar = true;
               this.headershow = false;
             }
             else
             {
               this.showtopbar = false;
               this.headershow = true;
               this.drawer = false;
             }
           }  
        },
        opendeposit()
        {
         this.dialogdeposit = true;
         this.error = false;
         this.errorText = '';
        },
      },
      destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    }
  
</script>
<style scoped>
.sidebarlogo{text-align:center; display: flex;text-decoration: none;font-size: 18px;font-weight: 100;color: #485664;font-family: 'Poppins', sans-serif;}
.sidebarlogo img {width: 47px;margin-top: 10px;margin-left: 10px;}
.sidebarlogo h1{margin-top: 11px;}
.marginright40 { margin-right: 40px;}
.marginright30 { margin-right: 30px;}
.marginright10 { margin-right: 10px;}
.topbalance { font-size: 14px;}
.toptitle {font-size: 10px;color: #010101;}
.topbarmenu a {color: #010101!important; font-size: 14px; font-family: Lexend; text-decoration: none;}
.toplogsmall{display: flex; text-decoration: none; font-size: 14px;font-weight: 100;color: #485664;font-family: 'Poppins', sans-serif;}
.displayonmob{display: none;}
.displaycurr{display: flex;}
@media(max-width:767px)
{
.notformobile{display: none;}
.sidebarlogo{height: 54px;}
.displayonmob{display: flex;border-bottom: 1px solid #007bce;}
.displayonmob .toptitle { color: #000;}
.balance {color: #247cdd;  font-size: 16px;}
}    
</style>  
<style>
.fill-height{min-height: 448px;}
.toplogo h1, .sidebarlogo h1{margin-left: 5px; font-family: 'Poppins', sans-serif;font-size: 30px; font-weight: 300; color: #000;}
</style>