<template>
  <div v-if="window.width <= 767">
    <carousel class="cursor" 
 :minSwipeDistance=20 paginationActiveColor="#FE7900" paginationColor="#000000" :autoplayHoverPause="false" :autoplay="true" :scrollPerPage="true" easing="linear" :loop="true" :speed=800 :perPage="1">
  <slide v-for="(val,key) in categories" :key="key">
    <router-link :to="{name: 'election'}" v-if="key==2">
      <div class="serviceBox " :class=val.color>
        <h3 class="title">{{ val.name }}</h3>
        <p class="description" v-html="val.description"></p>
        <div class="service-icon">
           <span><img :class="val.imgclass" :src="val.image" /></span>
        </div>
      </div>
    </router-link> 
    <router-link :to="{name: 'category',params:{'category':val.name}}" v-else>
      <div class="serviceBox " :class=val.color>
        <h3 class="title">{{ val.name }}</h3>
        <p class="description" v-html="val.description"></p>
        <div class="service-icon">
           <span><img :class="val.imgclass" :src="val.image" /></span>
        </div>
      </div>
    </router-link>  
  </slide>
   
</carousel>
  </div>  
  <div class="row mainrow" v-else>
    <div class="col-md-3 col-sm-6 cursor" v-for="(val,key) in categories" :key="key">
      <router-link :to="{name: 'election'}" v-if="key==2">
        <div class="serviceBox " :class=val.color>
          <h3 class="title">{{ val.name }}</h3>
          <p class="description" v-html="val.description"></p>
          <div class="service-icon">
             <span><img :class="val.imgclass" :src="val.image" /></span>
          </div>
        </div>
      </router-link> 
      <router-link :to="{name: 'category',params:{'category':val.name}}" v-else>
        <div class="serviceBox " :class=val.color>
          <h3 class="title">{{ val.name }}</h3>
          <p class="description" v-html="val.description"></p>
          <div class="service-icon">
             <span><img :class="val.imgclass" :src="val.image" /></span>
          </div>
        </div>
      </router-link>  
     </div>
   </div> 
</template>
<script>
import { Carousel, Slide } from 'vue-carousel';

export default{
  name:'Homecategory',
  components: {
    Carousel,
    Slide
  },
  data(){
    return{
       window: {width: 0,height: 0}, 
       categories:{
         0 : {'name':'Sports','color':'orangecolor','image': require('@/assets/sports.png'),'imgclass':'sportsicon','description':'Vote on every sport from <br/> around the World'},
         1 : {'name':'Anything','color':'bluecolor','image': require('@/assets/anything.png'),'imgclass':'anyicon','description':'Vote on questions about TV, movies, music, celebrities and more'},
         2 : {'name':'Elections','color':'graycolor','image': require('@/assets/vote.png'),'imgclass':'electionicon','description':'Vote on elections from every corner <br/> of the World'}
       },
      
    }
  },
  watch:{
    $route(){
      this.handleResize();
    }   
  },  
  created(){
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }, 
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods:{
   handleResize() 
   {
     this.window.width = window.innerWidth;
     this.window.height = window.innerHeight;
   }    
  }  
}
</script>
<style>
 @import '../assets/variables.css';
 section { padding: 60px 0; overflow: hidden;}
 :root{ --main-color: #fff; }
.serviceBox{
    color: #fff;
    background: var(--main-color);
    font-family: 'Poppins', sans-serif;
    text-align: right;
    padding: 25px 0;
    border-radius: 15px;
    box-shadow: 4px 4px 2px rgba(0,0,0,0.1);
}
.serviceBox .title{
    background: rgba(0,0,0,0.2);
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: left;
    width: 90%;
    padding: 10px 10px 10px 30px;
    margin: 0 0 15px;
    display: inline-block;
    clip-path: polygon(10% 0, 100% 0%, 100% 100%, 0% 100%);
}
.serviceBox .description{
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0.5px;
    text-align: center;
    line-height: 24px;
    margin: 0 20px 15px;
}
.serviceBox .service-icon{
    background: rgba(0,0,0,0.25);
    font-size: 40px;
    text-align: center;
    line-height: 85px;
    width: 85px;
    height: 85px;
    margin: 0 auto;
    border-radius: 50%;
}
.serviceBox.orangecolor{ --main-color: #FE7900; }
.serviceBox.bluecolor{ --main-color: #002F8B; }
.serviceBox.graycolor{ --main-color: #666666; }
.col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 30%;
    max-width: 30%;
}    
@media only screen and (max-width: 1199px){
    .serviceBox{ margin: 0 0 30px; }
}
@media only screen and (max-width: 767px){
    .serviceBox .title{ padding-left: 60px; }
}
@media only screen and (max-width: 479px){
    .serviceBox .title{ padding-left: 35px; }
    .serviceBox {
    margin: 0 0 10px;
}
}
.mainrow{justify-content: space-evenly; margin-top: 15px;}
.sportsicon{width: 80%;margin-top: 14px;}
img.electionicon {
    width: 66%;
    margin-left: 5px;
    margin-top: 8px;
}
.anyicon {
    width: 75%;
    margin-top: 11px;
}
.cursor{cursor: pointer;}
.cursor a,.cursor p{text-decoration: none;}
.VueCarousel{width: 80%; margin: auto;}
.VueCarousel-dot{margin-top: 0 !important}
.VueCarousel-dot-container{margin-top: 0 !important;}
</style>