<template>
  <div>
   <v-form @submit.prevent="validate" ref="form" lazy-validation>
    <v-card-text>
     <v-row>
      <v-col id="errordivtop">
        <template>
          <p class="text-justify">
            This sign up page is for DamnN International so although you are signing up for QuizStar or DROAD or Sportstrade it is all DamnN International.
          </p>
          <p class="text-justify">
            After you have filled out this form you will  receive a personal DamnN International number that will be sent to your email.
          </p>   
          <p class="text-justify"> 
            All the information is important because there will be opportunities that arise that will be in certain locations and areas to win rewards.
          </p>   
         </template> 
      </v-col>
    </v-row>
    <div v-if="error" class="mt-2 mb-3  " id="errordiv" ref="errordiv">
      
        <div class="red--text" v-html="errorText">
          
        </div>
    </div>
    <v-row>
      <v-col cols="6">
        <v-text-field v-model="firstname" hide-details outlined label="First Name" required></v-text-field>
      </v-col>
      <v-col col="12" md="6" sm="6">
        <v-text-field v-model="lastname" hide-details outlined label="Last Name" required></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12" md="6" sm="6">
        <v-text-field v-model="username" :rules="usernameRule" hide-details outlined label="Username"></v-text-field>
      </v-col>
      <v-col col="12" md="6" sm="6">
        <v-text-field v-model="email" :rules="emailRules" hide-details  outlined label="E-mail" required></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <date-pick class="dobpicker" :format="'DD/MM/YYYY'" v-model="dob">
          <template v-slot:default="{toggle}">
            <v-text-field  hide-details outlined  label="DOB" @click="toggle" v-on:keyup="formatdate($event)" placeholder="DD/MM/YYYY" v-model="dob">
            </v-text-field>
           </template>
              </date-pick>
      </v-col>
      <v-col cols="6">
        <v-select v-model="gender" hide-details outlined :items="genderarr" label="Gender" required></v-select>
      </v-col> 
     </v-row>  
     <v-row>
       <v-col cols="6">
          <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="passwordRule"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Password"
            hint="At least 6 characters"
            counter
            hide-details
            outlined
            @click:append="show1 = !show1"
          ></v-text-field>
                </v-col>
             
                <v-col cols="6">
                <v-text-field
                        v-model="confirmpassword"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="confirmpasswordRule"
                        :type="show2 ? 'text' : 'password'"
                        name="input-10-1"
                        label="Confirm Password"
                        hint="At least 6 characters"
                        counter
                        hide-details
                        outlined
                        @click:append="show2 = !show2"
          ></v-text-field>   </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select v-model="region" hide-details outlined :rules="regionRule" @change="stateslist($event)" :items="regions" label="Country" required></v-select>
                </v-col>
             
                <v-col cols="6">
                   <v-autocomplete id="stateid" hide-details outlined  @change="selectcities($event)" :rules="stateidRule" v-model="stateid" :items="statesarr" item-text="state_name" item-value="state_id"   
                       filled label="State"  class="p-0" ></v-autocomplete>
                </v-col>
              </v-row>
               <v-row>
                <v-col cols="6">
                   <v-autocomplete id="cityid" hide-details outlined   v-model="cityname" :items="citiesarr" item-text="city_name" item-value="city_id"   
                       filled label="City"  class="p-0" ></v-autocomplete>
                </v-col>
             
                <v-col cols="6">
                  <v-text-field v-model="suburb" hide-details outlined label="Suburb" required></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn type="submit" :disabled="makedisable" color="primary">Create Account</v-btn>
              <v-spacer />
            </v-card-actions>
         
          </v-form>
    
 
    </div>
</template>

<script>
import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';
export default {
  name: "Register",
  components: {DatePick},
  data() {
    return {
      logo: '',
      damnnogo: '',
      makedisable:false,
      region: "",
      regions: ["America", "Australia", "United Kingdom","New Zeland"],
      username:"",
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      gender:"",
      city:"", 
      stateid:"",
      cityname:"",
      suburb:"",
      statesarr:[],
      citiesarr:[],
      confirmpassword:"", 
      dob:"",
      from_date: "",
      fromdate: "2022-03-09",
      cities:[],
       show1: false,
       show2 : false,  
      loginusername : "",
      loginpassword : "",
      loginDamnnId : "",
      emailRules: [
        v => !!v || "Please enter your email address",
        v =>
          /.+@.+\..+/.test(v) ||
          "This doesn't appear to be a valid email address"
      ],
      passwordRule: [
        v => v.length >= 6 || "Password must be at least 6 characters"
      ],
      confirmpasswordRule: [
        v => v.length >= 6 || "Confirm Password must be at least 6 characters",
         v =>
          v === this.password || 'The password confirmation does not match.',
      ],
      regionRule: [
        v => !!v || "Pleas Select the country"
      ],
       cityRule: [
        v => !!v || "Please Select the city."
      ],
      stateidRule: [
        v => !!v || "Please Select the state."
      ],
       usernameRule: [
        v => !!v || "Please enter your username."
      ],
      genderarr:["Male","Female","Other"],
      loginusernameRule: [
        v => !!v || "Please enter username"
      ],
      loginpasswordRule: [
        v => !!v || "Please enter password"
      ],
      logindamnnidRule: [
        v => !!v || "Please enter DamnnId"
      ],
      tab: null,
      error:false,
      errorText:"",
      videodisplay:false
    };
  },
  mounted(){
    
  },
  methods: 
  {
    formatdate(event){
      var delimeter = "/";
       if(event.code != "Backspace")
       {
         if(this.dob.length == 2 || this.dob.length == 5)
         {
           this.dob = this.dob + delimeter;
         }
       }
       else
       {
          if(event.code == "Backspace")   
          {
             if(this.dob.length == 2)
            {
              this.dob = '';
            }
          } 
       }
    },   
    validate() {
      if (this.$refs.form.validate()) {
        this.register();
      }
    },
    register() {
      this.makedisable = true;
      this.$store
        .dispatch("register", {
          username  : this.username,
          firstname : this.firstname,
          lastname  : this.lastname,
          email     : this.email,
          password  : this.password,
          region    : this.region,
          referral  : this.referral,
          qrcodescan: this.qrcodescan,
          dob       : this.dob,
          gender    : this.gender,
          cityname  : this.cityname,
          suburb    : this.suburb,
          stateid   : this.stateid,
          app_type  : 'damnntips'
        })
        .then((response) => {
          if(response.data.status)
          {
            this.makedisable = false;
            //this.$store.commit('setActionModal',false);
            
            this.$store.dispatch("retrieveTokenRegister", 
                {username: this.email,password: this.password,damnnid:response.data.result.dammNId}).then(() => {
                
                this.$router.push({ name: "dashboard" });
            }).catch((error) => {
             this.error = true;
             this.errorText = error.response.data.message;
             this.makedisable = false;
            });
          }  
          else
          {
            this.error = true;
            this.errorText = response.data.message;
            this.makedisable = false;
          }
        })
        .catch(error => {
          this.error = true;
          this.makedisable = false;
          setTimeout(() => {
           const el = document.getElementById('errordivtop');
           var myval = [];
            var obj = error.response.data.data;
            Object.keys(obj).forEach(function(key) {
               myval.push(obj[key]);
            }); 
            this.errorText = myval;
           el.scrollIntoView({behavior: "smooth"});
          }, 100);
           
        });
    },
    getRegions(){
      this.$store.dispatch("retriveRegions").then(() => {
           this.regions = this.$store.getters.getregions;
      });
    },
     selectcities(getstate)
    {
    
       this.$store
        .dispatch("citieslist", {
          stateid    : getstate,
          countryname: this.region,
        })
        .then(response => {
          this.citiesarr = response.data;
          
        })
        .catch(error => {
          // TODO: handle incorrect login
          console.log(error);
        });
    },
      stateslist(getcountry)
    {
    
       this.$store
        .dispatch("stateslist", {
          countryname: getcountry,
        })
        .then(response => {
          this.statesarr = response.data;
        })
        .catch(error => {
          // TODO: handle incorrect login
          console.log(error);
        });
    }
    
    
  }
};
</script>
<style >
.text-justify{font-size:10px !important;margin-bottom:0px !important;}
.v-tab{color: #6cbcf4 !important;font-size: 20px !important;} 
.v-tab:before, .v-tabs-slider {
    background-color: #6cbcf4 !important;
}
.v-responsive__sizer{padding-bottom: 43.33% !important;}
.errortext {
    padding: 5px;
    color: white;
}
.dobpicker input {
  font-size: 16px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  padding-right: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  background: transparent;
  /*border: 1px solid #9e9e9e;
  box-shadow: 0 0.1em 0.3em rgb(0 0 0 / 5%);*/
  outline: 0;
}
.vdpHeadCellContent{font-size: 11px;}
.vdpComponent.vdpWithInput>input{color: black; }
.vdpComponent{width: 100%;}
</style>