<template>
  <div class="fill-height">
    <section id="hero-fullscreen" class="hero-fullscreen d-flex align-items-center">
      <div class="container mobcontainer d-flex flex-column align-items-center position-relative aos-animate">
        <h2 class="deskonly">Welcome to <span>myvoco</span></h2>
         
          <div class="mt-3 mb-3 mobdash" v-if="loggedIn">
            <Userprogress />
          </div>
          <div class="mt-3 mb-3" v-else>
            <v-btn v-if="!loggedIn" to="/auth?action=login" class="secondary">Login to see progress</v-btn>
          </div>
        
        <Homepagecategory />
        <div class="mt-4" v-if="questions.length > 0">
          <div v-for="(val,key) in questions" :key="key">
           <div v-if="key == 0" class="headingqtn"> Latest Question</div> 
           <div v-if="key == 1" class="headingqtn"> Most Voted</div> 
           <div v-if="key == 2" class="headingqtn"> Ending Soon</div> 
           <div class="question" @click="gotoqst(val.id)">
             Q. {{ val.question }}
           </div>
          </div> 
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import Homepagecategory from '../components/Homecategory.vue';
  import Userprogress from '../components/Userprogress.vue'

  export default {
    name: 'Home',
    components: {
       Homepagecategory,
       Userprogress
    },
    data(){
      return{
        window: {width: 0,height: 0}, 
        questions:[]
      }
    },
    watch:{
    $route(){
      this.handleResize();
    }   
  }, 
    computed:{
        loggedIn() {
          return this.$store.getters.loggedIn;
        },
    },
    
    mounted(){
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      this.$root.$refs.Home = this;
      if(typeof this.$route.hash != "undefined")
      {
         var pathname = this.$route.hash;
         this.scrollto(pathname.replace("#",""));
      }
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods:
    {
     gotoqst(id){
       this.$router.push("/question/"+id);
     }, 
     handleResize() 
     {
     this.window.width = window.innerWidth;
     this.window.height = window.innerHeight;

     if(this.window.width <= 767)
     {
        this.$store.dispatch("getLatestQstHome").then((response) =>{
          this.questions = response.data;
        });
     }
   },
      scrollto(sectionname){
        
        if(sectionname != '')
        {
          if(this.$route.name == 'home'  && this.$route.hash == "")
          {
            this.$refs[sectionname].scrollIntoView({ behavior: "smooth" })
          }
          else
          {
             
            if(this.$route.name != 'home' && this.$route.hash == "")
            {
              this.$router.push("/#"+sectionname);
            }
            else
            {
              this.$nextTick(function () {
                this.$refs[sectionname].scrollIntoView({ behavior: "smooth" })
              })
            }
          }
        } 
      },
    }
  }
</script>

<style >
.headingqtn {
    font-size: 17px;
    font-weight: bold;
    font-style: italic;
}
.question {
    background-color: white;
    margin-top: 0;
    margin-bottom: 15px;
    width: 98%;
    padding: 8px;
    cursor: pointer;
    border-radius: 8px;
}
 .hero-fullscreen { width: 100%; min-height: 100vh; background: url(../assets/My-Vote.png) center center; background-size: cover;position: relative;padding: 120px 0 60px;}
 .aos-animate { opacity: 1; transform: translateZ(0) scale(1);}
  
 .align-items-center { align-items: center!important;}
 .hero-fullscreen:before { content: "";  background: rgba(var(--color-white-rgb), 0.85);  position: absolute;  inset: 0;}
.hero-fullscreen h2 { margin: 0 0 10px 0;font-size: 48px;font-weight: 300;color: #000;font-family: var(--font-secondary);}
.hero-fullscreen .btn-get-started {font-size: 16px;font-weight: 400;display: inline-block; padding: 10px 28px;border-radius: 4px;transition: 0.5s;color: var(--color-white); background: var(--color-secondary);font-family: var(--font-secondary);}
a{text-decoration: none;}
.featured-services .service-item { padding: 30px;transition: all ease-in-out 0.4s;background: var(--color-white); height: 100%;}
.aos-animate { opacity: 1; transform: translateZ(0) scale(1);}
.stretched-link::after {position: absolute;top: 0;right: 0;bottom: 0;left: 0; z-index: 1;content: "";}
  
.section-header {text-align: center; padding-bottom: 40px;}
.section-header h2 {font-size: 48px;font-weight: 300;margin-bottom: 20px;color: var(--color-secondary-dark);} 
.about .nav-pills {border-bottom: 1px solid rgba(var(--color-secondary-rgb), 0.2);}
.about .about-img:before {position: absolute;inset: -60px 0 0 -60px;z-index: -1;content: ""; background: url(../assets/about-bg.png) top left;background-repeat: no-repeat;}
.about .about-img {position: relative; margin: 60px 0 0 60px;}
.nav-pills {-bs-nav-pills-border-radius: 0.375rem;--bs-nav-pills-link-active-color: #fff;--bs-nav-pills-link-active-bg: #0d6efd;}
.img-fluid {max-width: 100%;height: auto; position: relative; z-index: 99;}
.about .about-img:before {position: absolute;inset: -60px 0 0 -60px; z-index: 1;content: ""; background: url(../assets/about-bg.png) top left; background-repeat: no-repeat;}
.faqback{background: url("../assets/faq.jpg");background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 300px;}
.about h3 {
    color: var(--color-secondary-dark);
    font-family: var(--font-secondary);
    font-weight: 300;
    font-size: 32px;
    margin-bottom: 20px;
}
.faq .content h3 {
    font-weight: 400;
    font-size: 34px;
    color: var(--color-secondary-dark);
}
.faq .content p {
    font-size: 15px;
    color: var(--color-gray);
}
.deskonly{display: block;}
@media (min-width: 1365px) {
  .hero-fullscreen {  background-attachment: fixed;}
}  
@media (max-width: 768px) {
  .hero-fullscreen {  min-height: inherit;}
  .hero-fullscreen h2{font-size: 42px; margin: 0;}
  .img-fluid{z-index: 4;}
}  
@media (max-width: 767px) {
  .deskonly{display: none;}
}  
@media (max-width: 480px) {
  .mt-3.mb-3.mobdash{margin-top: 0px !important;}
  .hero-fullscreen {padding: 5px 0 60px; align-items: inherit !important;}
  .container.mobcontainer{padding: 6px;}
}  
</style>
 