<template>
    <div class="fill-height">
      <div class="container mt-10 mb-10">
          <div class="toplogo text-center">
               <div class="back-button">
                  <v-btn to="/dashboard" color="primary" >
                      <span class="material-icons">keyboard_arrow_left</span> Dashboard
                   </v-btn>
               </div>
               <h1>Question Asked</h1>
          </div>     
          <div class="showdata">
            <Userquestion />
          </div> 
      </div>
    </div>      
  </template>
  <script>
  import Userquestion from '../components/Userquestion.vue';
  
  export default {
      name: 'Questionasked',
      components:{
        Userquestion,
    },
  }    
  </script>
  <style scoped>
  
  .showdata{margin-top: 20px;}
   
  </style>