<template>
  <v-app>
     <Master />
  </v-app>
</template>

<script>
import Master from '../src/components/Master.vue'
export default {
  name: 'App',
   components: {
    Master
  },
  data: () => ({
    //
  }),
};
</script>
