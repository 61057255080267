<template>
 <div class="fill-height">
   <div class="container mt-10 mb-10 align-items-center mobdiff">
    <div class="toplogo text-center">
             <div class="back-button">
                <v-btn to="/dashboard" color="primary" >
                    <span class="material-icons">keyboard_arrow_left</span> Dashboard
                 </v-btn>
             </div>
             <h1>Ask your question</h1>
        </div>     
     <div class="dashboard mt-10">
       <h2 class="text-center"></h2>
       <div class="question-area" v-show="first">
          <div class="column">
            <v-select  :items="categories" @change="getSubcat()" item-text="name" item-value="id" label="Choose Section" v-model="category" hide-details outlined></v-select>
          </div>
          <div class="errorclass" v-if="error.category">{{ error.category }}</div>
          <div class="column">
            <v-select  :items="subcategories" item-text="name" item-value="id" label="Choose Category" v-model="subcategory" hide-details outlined></v-select>
          </div>
          <div class="errorclass" v-if="error.subcategory">{{ error.subcategory }}</div>
          <div class="column">
            <v-textarea label="Question" auto-grow rows="2" row-height="25" v-model="question" hide-details outlined></v-textarea>
          </div>
          <div class="errorclass" v-if="error.question">{{ error.question }}</div>
          <div class="column optionaction">
            <div><span class="boldheading">Options:</span></div>
            <div class="optionsval">
                <div class="optname"><v-text-field  name="option" outlined  type="text" v-model="options[0]" hide-details/></div>
                <div class="optact">&nbsp;</div>
            </div>
            <div class="optionsval">
                <div class="optname"><v-text-field  name="option" outlined  type="text" v-model="options[1]" hide-details/></div>
                <div class="optact">&nbsp;</div>
            </div>
            <div class="optionsval" v-for="(input,k) in inputs" :key="k">
                <div class="optname"><v-text-field  name="option" outlined  type="text" v-model="options[k+2]" hide-details/></div>
                <div class="optact"><v-btn color="red" class="white--text" @click="removeField(k)">Delete</v-btn></div>
            </div>
            <div>
                <v-btn color="green" class="white--text"  @click="addField()">Add More option</v-btn>
            </div>
          </div>
          <div class="errorclass" v-if="error.options">{{ error.options }}</div>
          <div class="column text-right mt-5 bigbtn">
            <v-btn color="secondary" @click="next()">Next</v-btn>
          </div>
       </div> 
       <div class="action-area" v-show="second">
          <div class="column">
            <div><span class="boldheading">Start Time</span></div>
            <div><v-datetime-picker ref="dateElement" date-format="dd-MM-yyyy" time-format="HH:mm:ss" hide-details outlined @input="getstarttime()" v-model="starttime"> </v-datetime-picker></div>
            <div><span class="italic">(if you want to display question on future date and time otherwise it will display on submit)</span></div>
          </div>
          <div class="errorclass" v-if="error.startimeerror">{{ error.startimeerror }}</div>
          <div class="column">
            <div><span class="boldheading">End Time</span></div>
            <div><v-datetime-picker ref="dateElementother" date-format="dd-MM-yyyy" time-format="HH:mm:ss" hide-details outlined @input="getendtime()" v-model="endtime"> </v-datetime-picker></div>
            <div><span class="italic">(if you want to hide or disable question on particular time otherwise it will stop after limit reached)</span></div>
          </div>
          <div class="errorclass" v-if="error.endtimeerror">{{ error.endtimeerror }}</div>
          <div class="column">
            <div><span class="boldheading">Total Prize Pot</span></div> 
            <div class="innerdflex">
               <div class="inerhead">Kels</div>
               <div class="inerval">
                  <v-text-field  name="option" outlined  type="text" v-model="kels" hide-details/>
                  <div class="errorclass" v-if="error.kelserror">{{ error.kelserror }}</div>
                </div>
               <div class="inerhead">$</div>
               <div class="inerval">
                 <v-text-field  name="option" outlined  type="text" v-model="cents" hide-details/>
                 <div class="errorclass" v-if="error.centserror">{{ error.centserror }}</div>
              </div>
            </div> 
          </div>
          <div class="column">
            <div><span class="boldheading">Answer Fee</span></div> 
            <div class="innerdflex">
               <div class="inerhead">Kels</div>
               <div class="inerval">
                 <v-text-field  name="option" outlined  type="text" v-model="anskels" hide-details/>
                 <div class="errorclass" v-if="error.anskelserror">{{ error.anskelserror }}</div>
               </div>
               <div class="inerhead">$</div>
               <div class="inerval">
                 <v-text-field  name="option" outlined  type="text" v-model="anscents" hide-details/>
                 <div class="errorclass" v-if="error.anscentserror">{{ error.anscentserror }}</div>
               </div>
            </div> 
          </div>
          <div class="column">
            <div><span class="boldheading">Answer</span></div> 
            <div><input type="radio" name="answerby" v-model="answerby" value="me"> Decide by me</div>
            <div><input type="radio" name="answerby" v-model="answerby" value="vote"> Decide by vote</div>
          </div>
          <div class="column-flex mt-10">
            <div class="leftpart"><v-btn color="secondary" @click="prev()">Edit Question</v-btn></div>
            <div class="rightpart"><v-btn color="primary" @click="save()">Save</v-btn></div>
          </div>
       </div>
     </div>
   </div>
   <v-dialog transition="dialog-bottom-transition" max-width="300" v-model="dialog">
          <v-card>
            <v-toolbar color="primary" dark>Alert</v-toolbar>
            <v-card-text>
              <div class="mt-5 message">{{ message }}</div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="closedialog()">Close</v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>
    <Loading :displayDialog="showdialog" />
 </div>   
</template>
<script>
  import Loading from '../components/Loader.vue';

  export default {
    name: 'Addquestion',
    components:{
      Loading
    },
    data(){
      return{
        categories:[],
        subcategories:[],
        category:'',
        subcategory:'',
        question:'',
        options: [],
        inputs:[],
        first:true,
        second:false,
        starttime:'',
        laststarttime:'',
        endtime:'',
        lastendtime:'',
        kels:0,
        cents:0,
        anskels:0,
        anscents:0,
        dialog:false,
        message:'',
        error:{'category':'','subcategory':'','question':'','options':'','startimeerror':'','endtimeerror':'', 
               'kelserror':'','centserror':'','anskelserror':'','anscentserror':''},
        starttimeerror:false,
        endtimeerror:false,
        starttimemsgerror:'',
        endtimemsgerror:'',
        showdialog:false,
        showdeposite:false,
        answerby:'me'
      }
    },
    created(){
      this.getCategories();
    },    
    methods:{
      closedialog(){
        this.dialog = false;
        this.message = '';
        if(this.showdeposite)
        {
          this.$root.$refs.Master.opendeposit();
        }
      },
      getNumber(){
        return this.options.length;
      },
      getSubcat(){
        this.$store.dispatch("getSubcategory",{category:this.category}).then((response) =>{
            this.subcategories = response.data;  
        });
      },
      save()
      {
        if((!this.endtimeerror && !this.starttimeerror) && this.kels != 0 && this.cents != 0 && this.anskels != 0 && this.anscents != 0)
        {
          //this.showdialog = true;
          this.$store.dispatch("checkBalance",{kels:this.kels,cents:this.cents}).then((response) => 
          {
             if(!response.status)
             {
               this.message = response.message;
               this.dialog = true;
               this.showdeposite = true;
               this.showdialog = false;
             }
             else
             {
               this.$store.dispatch("submitQuestion",
                  {category:this.category,subcategory:this.subcategory,question:this.question,options:this.options,
                   starttime:this.laststarttime,endtime:this.lastendtime,kels:this.kels,cents:this.cents,
                   anskels:this.anskels,anscents:this.anscents,answerby:this.answerby}).then((response) => {
                this.showdialog = false;
                 if(response.status)
                 {
                   this.message = response.message;
                   this.dialog = true;
                   this.showdeposite = false;
                   this.$store.dispatch("retrieveBalance");
                   setTimeout(() => this.$router.push({ name: "dashboard" }), 2000);
                 }
               });
               
             }
             
          });
        }
        else
        {  
           if(this.endtimeerror)
           {
             this.error.endtimeerror = this.endtimemsgerror;
           } 
           if(this.starttimeerror)
           {
             this.error.startimeerror = this.starttimemsgerror;
           } 
           if(this.kels == 0)
             this.error.kelserror = 'Enter kels';
           else
             this.error.kelserror = '';

           if(this.cents == 0)
             this.error.centserror = 'Enter cents';
           else
             this.error.centserror = ''; 
             
           if(this.anskels == 0)
             this.error.anskelserror = 'Enter kels';
           else
             this.error.anskelserror = ''; 
           
           if(this.anscents == 0)
             this.error.anscentserror = 'Enter cents';
           else
             this.error.anscentserror = '';
           
        }
      },
      next()
      {
        if(this.category > 0 && this.subcategory > 0 && this.question.length > 0 && this.options.length >= 2)
        {
          this.first = false;
          this.second = true;
        }
        else
        {
           if(this.category == 0)
             this.error.category = "Select one section atleast.";
           else
             this.error.category = '';

           if(this.subcategory == 0)
             this.error.subcategory = "Select one category atleast.";
           else
             this.error.subcategory = '';  

           if(this.question.length == 0)
             this.error.question = "Write something in question.";
           else
             this.error.question = '';
           
           if(this.options.length < 2)
             this.error.options = "Write atleast 2 options.";
           else
             this.error.options = '';
        }
      },
      prev(){
        this.error = {};
        this.first = true;
        this.second = false;
      },
      getCategories(){
        this.$store.dispatch("getcategories").then((response) => {
           this.categories = [{id:0,name:"Choose Category"}]; 
           if(response.data.length > 0)
           {
            for(var i = 0; i < response.data.length; i++)
             {
               this.categories.push({ id:response.data[i].id, name:response.data[i].name });
             }     
           }
        });
      },
      addField() {
        this.inputs.push({ value: "" });
      },
      removeField(index) {
        this.options.splice(index+2,1);
        this.inputs.splice(index, 1);
      },  
      getstarttime()
      {
        var dateObject = new Date(this.starttime);
        var year    = dateObject.getFullYear();
        var month   = ('0' + (dateObject.getMonth() + 1)).slice(-2); // Months are 0-based
        var day     = ('0' + dateObject.getDate()).slice(-2);
        var hours   = ('0' + dateObject.getHours()).slice(-2);
        var minutes = ('0' + dateObject.getMinutes()).slice(-2);
        var seconds = ('0' + dateObject.getSeconds()).slice(-2);
        var formattedDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
          
        var givenDate   = new Date(formattedDate);  
        var currentDate = new Date();
        if (givenDate >= currentDate) 
        {
          if(this.lastendtime == '')
          {
            this.laststarttime = formattedDate;
            this.starttimeerror = false;
          }
          else
          {
              var givenDateAgain   = new Date(formattedDate);  
              var currentDateAgain = new Date(this.lastendtime);
              if(givenDateAgain < currentDateAgain) 
              {
                this.laststarttime = formattedDate;
                this.starttimeerror = false;
              } 
              else 
              {
                this.message = "You can publish question within 7 days";
                this.starttimeerror = this.message;
                this.dialog = true;
                this.starttimeerror = true;
              }
          }
        } 
        else 
        {
          this.message = "Do not choose older date and time";
          this.starttimemsgerror = this.message;
          this.dialog = true;
          this.starttimeerror = true;
        }
      },
      getendtime()
      {
        this.message = ''; 
        var dateObject = new Date(this.endtime);
        var year = dateObject.getFullYear();
        var month = ('0' + (dateObject.getMonth() + 1)).slice(-2); 
        var day = ('0' + dateObject.getDate()).slice(-2);
        var hours = ('0' + dateObject.getHours()).slice(-2);
        var minutes = ('0' + dateObject.getMinutes()).slice(-2);
        var seconds = ('0' + dateObject.getSeconds()).slice(-2);
        var formattedDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
          
        var givenDate   = new Date(formattedDate);  
        var currentDate = new Date();
        //above check if date is not less than today date 
        //below in if condition check start date is present and check not more than 7 days
        if(givenDate >= currentDate) 
        {
           if(this.laststarttime != '')
           {
              if(this.areDatesWithinSevenDays(this.laststarttime, givenDate)) 
              {
                this.lastendtime = formattedDate;
                this.endtimeerror = false;
              } 
              else 
              {
                this.message = "You can publish question within 7 days";
                this.endtimemsgerror = this.message;
                this.dialog = true;
                this.endtimeerror = true;
              }
           }
           else
           {
              var currentDateNew = new Date(); 
              if(this.areDatesWithinSevenDays(currentDateNew, formattedDate)) 
              {
                this.lastendtime = formattedDate;
                this.endtimeerror = false;
              } 
              else 
              {
                this.message = "You can publish question within 7 days";
                this.endtimemsgerror = this.message;
                this.dialog = true;
                this.endtimeerror = true;
              }
           } 
        } 
        else 
        {
           this.message = "Do not choose older date and time";
           this.dialog = true;
           this.endtimeerror = true;
           this.endtimemsgerror = this.message;
        }   
      },
      areDatesWithinSevenDays(dateString1, dateString2) 
      {
        // Convert date strings to Date objects
        var startDate = new Date(dateString1);
        var endDate = new Date(dateString2);
        // Calculate the absolute difference in milliseconds
        var timeDifference = Math.abs(endDate - startDate);
        // Calculate the number of milliseconds in 7 days
        var sevenDaysInMillis = 7 * 24 * 60 * 60 * 1000;
        // Check if the difference is within 7 days
        return timeDifference < sevenDaysInMillis;
      }
    }  
  }
</script>    
<style scoped>
.column{margin-top: 10px;}
.optionsval{margin-bottom: 10px; margin-top: 5px;}
.optionaction{width: 70%;}
.optionaction div.optname{flex:0 0 90%}
.optionaction div.optact{flex:0 0 10%; margin-left: 5px;}
.optionsval{display: flex; align-items: center;}
.small-text{font-size: 12px;}
.column-flex{display: flex;}
.column-flex > div{flex: 0 0 50%;}
.column-flex .rightpart{text-align: right;}
.column-flex .rightpart button{width: 150px;}
.bigbtn button{width: 150px;}
.italic{font-size: 11px; font-style: italic;}
.boldheading{font-weight: bold;}
.innerdflex{display: flex; margin-top: 10px; align-items: center; }
.innerdflex div.inerhead{flex: 0 0 8%;}
.innerdflex div.inerval{flex: 0 0 15%; margin-right: 15px;}
.message{color: black;}
.errorclass{color: red; font-size: 12px; margin-top: 3px;}
.errorclass.d-flex  div{margin-right: 10px;}
@media(max-width:767px)
{
  .innerdflex div.inerhead{flex: 0 0 15%;}
  .innerdflex div.inerval{flex: 0 0 30%; margin-right: 15px;}
}  
</style>