<template>
  <div class="fill-height">
    <div class="container mt-10 mb-10">
        <div class="toplogo text-center">
             <div class="back-button">
                <v-btn to="/dashboard" color="primary" >
                    <span class="material-icons">keyboard_arrow_left</span> Dashboard
                 </v-btn>
             </div>
             <h1>Question Played</h1>
        </div>     
        <div class="showdata">
          <div v-if="data.length == 0" class="showquestion text-center">
             No Question Attempt Yet
          </div>
          <div v-else>
            <div class="showquestion" v-for="(val,key) in data" :key="key">
               <div class="displaything">
                  <div>Q. {{ val.question }}</div>
                  <div class="mt-5 showcounter" v-if="val.timedout == 0">
                    <div><flip-countdown :deadline=val.endtime></flip-countdown></div>
                    <div></div>
                  </div>
                  <div class="mt-5" v-else>
                     <div class="actbtnsdash">
                       <div><v-btn @click="toggle(key)" class="secondary">Show options</v-btn></div>
                       <div></div>
                     </div>
                     <div class="options"  v-show="isOpenIndex === key">
                      <v-radio-group class="ansgroup" v-model="answer[key]">
                          <v-radio v-for="(vals,keys) in val.options" disabled :key="keys" color="secondary" :value="keys">
                            <template v-slot:label>
                              <div class="makefullwidth">
                                <div :class=vals.color>{{ vals.value }}</div>
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                     </div>
                  </div>
               </div>
            </div>
          </div>
        </div> 
    </div>
  </div>      
</template>
<script>
import FlipCountdown from 'vue2-flip-countdown';
export default {
    name: 'Attemptquestion',
    data(){
      return{
         data:[],
         answer:[],
         isOpenIndex:'-1',
      }
    },
    components:{
      FlipCountdown,
    },
    mounted(){
      this.geQuestions();
    },
    methods:{
         
        geQuestions(){
            this.$store.dispatch("getAttemptQuestion").then((response) => {
                if(response.data.length > 0)
               {
                  for (let index = 0; index < response.data.length; index++) 
                  {
                    const element = response.data[index];
                    this.data.push({id:element.id,
                                    question:element.question,
                                    options:element.options,
                                    endtime:element.endtime,
                                    timedout:element.timedout,
                                  });  
                     
                    this.answer[index] = element.choosed;
                  }
               }
            })
        },
        toggle(index)
        {
          this.isOpenIndex = ( this.isOpenIndex == index ) ? null : index;
        },    
    }     
}    
</script>
<style scoped>

.showdata{margin-top: 20px;}
.showquestion{width: 80%; margin: auto; margin-top: 5px; box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; padding: 15px; min-height: 60px; border-radius: 10px;}
.showcounter,.actbtnsdash {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.makefullwidth{min-width: 50%; display: block;}

</style>
<style>
.choosegreen{color: rgb(64, 223, 64); font-weight: bold;}
.choosered{color: rgb(233, 52, 52); font-weight: bold;}
.choosegray{color: gray;}
</style>