import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md', // default - only for display purposes
      },
      theme: {
        themes: {
          light: {
            primary: '#002F8B',
            secondary: '#FF7802',
            accent: '#8c9eff',
            error: '#b71c1c',
            white:'#fff'
          },
          dark:{
            primary: '#000000',
            secondary: '#b0bec5',
            accent: '#8c9eff',
            error: '#b71c1c',
            white:'#fff'
          }
        },
      },   
});
