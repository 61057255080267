<template>
  <div class="fill-height">
    <div class="container">
     
     <div class="mobfilter" v-if="window.width <= 480">
       <span class="text-center">Filters</span>
       <div class="filterarrow" @click="showallfilter()">
        <span class="material-icons" v-if="!mobfiltershow">keyboard_arrow_down</span>
        <span class="material-icons" v-else>keyboard_arrow_up</span>
       </div>
     </div>
     <div class="filter" v-show="mobfiltershow">
      <div>
          <v-select  :items="categories" @change="getSubcat()" item-text="name" item-value="id" label="Choose Section" v-model="selectedcategory" hide-details outlined></v-select>
        </div>
        <div>
          <v-select  :items="subcategories" item-text="name" item-value="id" label="Choose Category" v-model="selectedsubcategory" hide-details outlined></v-select>
        </div>
        <div>
          <v-text-field label="Search by keyword" name="search" outlined  type="text" hide-details  v-model="search"/>
        </div>
        <div>
          <v-select  :items="filters" item-text="name" item-value="id" label="Choose Criteria" v-model="selectedfilter" hide-details outlined></v-select>
        </div>
        <div>
           <v-btn @click="filter()" color="black" class="white--text">Search</v-btn>
        </div>
     </div>
     <div class="question-list" v-if="questions.length > 0">
        <div class="question-block" v-for="(val,key) in questions" :key="key">
           <div class="showamount">
             <div class="items">
               <div class="makeitbtn"><span>${{ val.amount }}</span> <span>K{{ val.kels }}</span></div>
             </div>
             <div class="items">
               <v-btn class="potamnt" color="secondary">
                <span>${{ val.potamount }}</span> <span>K{{ val.potkels }}</span>
               </v-btn>
            </div>
           </div>
           <div class="qtn">Q. {{ val.question }}</div>
           <div class="actiontab">
              <div class="countdown firstchild">
                <flip-countdown :deadline=val.endtime></flip-countdown>
              </div>
              <div class="secondchild">
                <v-btn class="mybtn" :to="getLink(val.id)" color="primary">
                   <span v-if="val.completed == 0">Answer this <br/> question</span>
                   <span v-else>View Answer</span>
                </v-btn>
              </div>
              <div class="thirdchild">
                <div class="showansavatar" v-if="val.displayvote.length > 0">
                   <div class="smallava" v-for="(value,key) in val.displayvote" :key="key">
                      <img :src="value" />
                   </div>
                </div>
                <div class="remainvotes" v-if="val.totalvote > 0">+{{ val.totalvote }} votes</div>
                <div v-else>&nbsp;</div>
              </div>
           </div>
        </div>
     </div>
     <div class="question-list nodata" v-else>No Questions Available Right Now</div>
  </div>
  <v-dialog transition="dialog-bottom-transition" max-width="300" v-model="dialog">
      <v-card>
        <v-toolbar color="primary" dark>Alert</v-toolbar>
        <v-card-text>
          <div class="mt-5 message">{{ message }}</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="closedialog()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  <Loading :displayDialog="showdialog" />
</div> 
</template>
<script>
import Loading from '../components/Loader.vue';
import FlipCountdown from 'vue2-flip-countdown'

export default {
    name: 'Category',
    components:{
      Loading,
      FlipCountdown
    },
    data()
    {
      return{
        mobfiltershow:true,
        showfilter:false,
        showdialog:false,
        categories:[],
        subcategories:[],
        questions:[],
        category:0,
        search:'',
        selectedcategory:0,
        selectedsubcategory:0,
        filters:[{'id':'most votes','name':'Most Votes'},{'id':'latest question','name':'Latest Question'},{'id':'ending soon','name':'Ending Soon'}],
        selectedfilter:'',
        dialog:false,
        message:'',
        window: {width: 0,height: 0},
      }
    },
    watch:{
        $route(){
          this.handleResize();
         }   
    },
    created(){
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      this.showdialog = true;
      if(typeof this.$route.params.category != "undefined")
      {
         this.category = this.$route.params.category;   
      }
      this.getCategories();
      this.getQuestions();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods:{
      handleResize() 
        {
          
           this.window.width = window.innerWidth;
           this.window.height = window.innerHeight;
           
           if(this.window.width <= 480)
           {
             this.mobfiltershow = false;
           }
           else
           {
            this.mobfiltershow = true;
           }
      }, 
      showallfilter(){
         this.mobfiltershow =! this.mobfiltershow;
      },   
      getLink(id){
         let value = '/question/'+id; 
         return value;
      },
      closedialog(){
          this.dialog = false;
          this.message = '';
      },
      getCategories(){
        this.$store.dispatch("getcategories").then((response) => 
        {
           this.categories = [{id:'',name:"Choose Section"}]; 
           if(response.data.length > 0)
           {
            for(var i = 0; i < response.data.length; i++)
             {
               this.categories.push({ id:response.data[i].id, name:response.data[i].name });
             }     
           }
        });
      },
      getSubcat(){
        this.$store.dispatch("getSubcategory",{category:this.selectedcategory}).then((response) =>{
            this.subcategories = response.data;  
        });
      },
      filter(){
         if(this.search == '' && this.selectedcategory == 0 && this.selectedsubcategory == 0)
         {
           this.message = "Choose category or enter something to search";
           this.dialog = true;
         } 
         else
         {
           this.showdialog = true;
           this.getQuestions();
         }
      },
      getQuestions()
      {
         var category    = this.category; 
         var subcategory = this.selectedsubcategory;
         var keyword     = this.search;
         var sortyby     = this.selectedfilter;

         if(this.selectedcategory > 0) 
         {
           var findarrIndex       = this.categories.findIndex(p => p.id == this.selectedcategory); 
           category = this.categories[findarrIndex].name; 
         } 
         this.questions = [];
         this.$store.dispatch("getQuestions",{category:category,subcategory:subcategory,keyword:keyword,sortyby:sortyby}).then((response) => 
         {
             if(response.data.length > 0)
             {
               this.questions = response.data;
             }
             this.showdialog = false;
         });
      },
    }
}          
</script>
<style scoped>
.mobfilter{border-radius: 8px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; padding: 15px; font-weight: bold;    width: 100%;  position: relative;}
.mobfilter > span {  display: block;  text-transform: uppercase;}
.filterarrow {  position: absolute;  right: 8px;  top: 14px;}
.filter{display: flex; align-items: center; border-radius: 8px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; padding: 15px; margin-bottom: 25px; margin-top: 10px;}
.filter > div{margin-right: 10px; margin-top: 5px; margin-bottom: 5px;}
.filter div:nth-child(1){ flex: 0 0 21%; }
.filter div:nth-child(2){ flex: 0 0 21%; }
.filter div:nth-child(3){ flex: 0 0 21%; }
.filter div:nth-child(4){ flex: 0 0 21%; }
.filter div:nth-child(5){ flex: 0 0 12%; text-align: center;} 
.nodata{text-align: center; font-weight: bold; margin-top: 50px;}
.question-block{min-height: 65px; padding: 15px; margin-top: 15px; border-radius: 8px; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;}
.actiontab{margin-top: 15px;text-align: center; display: flex; align-items: center;}
.actiontab div.secondchild{flex: 0 0 60%;}
.actiontab div.firstchild,.actiontab div.thirdchild{text-align: left; flex: 0 0 19%;}
.actiontab div.thirdchild {display: flex; align-items: center;}
.actiontab-item span{margin-right: 5px;}
.actiontab-item:nth-child(1) {display: block;flex-grow: 0;flex-shrink: 1;flex-basis: auto;align-self: auto;order: 0;width: 25%;}
.actiontab-item:nth-child(2) {display: block;flex-grow: 0;flex-shrink: 1;flex-basis: auto;align-self: auto;order: 0;width: 25%;text-align: right;}
.mybtn{text-align: center;}
.qtn{font-family: 'Poppins', sans-serif;}
.showansavatar {display: flex; justify-content: right;}
.remainvotes {  flex: 0 0 40%;  font-size: 13px;}
.showansavatar > div {
    flex: 0 0 20%;
    margin-right: 5px;
    border-radius: 50%;
    overflow: hidden;
}
.smallava img {
    width: 85%;
}
@media(max-width:1180px)
{
  .showamount div.items{width: 13%;}
  .actiontab div.firstchild, .actiontab div.thirdchild {flex: 0 0 25%;}
  .actiontab div.secondchild{flex: 0 0 50%;} 
} 

@media(max-width:820px)
{
  .showamount div.items{width: 15%;}
}  
@media(max-width:768px){
  .filter div{flex: 0 0 19% !important;}
  
}
@media(max-width:680px)
{
  .showamount div.items{width: 17%;}
}  
@media(max-width:480px)
{
  .filter{display: block;}
  .filter div{margin-bottom: 10px;}
  .showamount{margin-bottom:10px}
  .showamount div.items { width: 30%;}
  .actiontab{flex-direction: column;}
  .actiontab div.firstchild{order: 2;}
  .actiontab div.secondchild{order: 1; margin-bottom: 10px;}
  .actiontab div.thirdchild{order: 3; margin-bottom: 10px;}
  .showansavatar > div{flex: 0 0 15%;}
} 
@media(max-width:400px)
{
  .showamount div.items{ width: 34%;}
}  
@media(max-width:320px)
{
  .showamount div.items{ width: 40%;}
}   
</style>
<style>
.showamount{display: flex; align-items: center; justify-content: right; margin-bottom: 7px;}
.makeitbtn { width: 98%; margin: auto; padding: 6px; border-top: 2px solid #ff7325; border-bottom: 2px solid #ff7325; border-right: 4px solid #ff7325; border-left: 4px solid #ff7325; border-radius: 6px; color: #ff7325; font-size: 14px; font-weight: bold ; }
.makeitbtn span:nth-child(1){margin-right: 5px;}
.potamnt span:nth-child(1){margin-right: 5px;}
.showamount div.items{width: 10%; text-align: center;}
.showamount div.items:nth-child(2){margin-left: 10px;}
.flip-clock__slot{
    font-size: 10px !important;
}
.flip-card{
    font-size: 1.1rem !important;
}
.container.flip-clock{padding: 0 !important;}
.flip-card__top, .flip-card__bottom, .flip-card__back-bottom, .flip-card__back::before, 
.flip-card__back::after{color: #ff7325 !important;}
</style>