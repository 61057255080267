<template>
  <v-dialog  transition="dialog-bottom-transition"  max-width="400"   v-model="dialogdeposit"  content-class="depositmodal" @click:outside="closeDialog">
    <v-card class="rounded-xl">
      <v-card-text >
        <div class="popupbody">
          <div class="row">
            <div class="heading col-md-10 col-xs-10">Deposit</div>
            <div class="closebtn col-md-2 col-xs-2">
               <span class="material-icons closeicon"  @click="closeDialog">close</span>
            </div>
          </div> 
          <div v-if="depositscreenfirst">
            <div class="totalamnt text-center">
              <div class="headfirst">Total Balance</div>
              <div class="amntdiv mt-5 myflex"><div>{{depositpricedisplay}}</div></div>
            </div>
            <div class="pricerange mt-5 pb-5">
               <v-slider step="1" min="1" max="3000" disabled track-color="white" tick-size=30 color="themebtn" v-model="depositprice"></v-slider>
            </div> 
            <div class="pricebtns">
              <div class="prices" @click="changeprice(599)" :class="{'active':depositprice == 599  }">
                  <div class="pricesymbol">
                    <div>$5.99</div> <div>+</div> <div>K300</div>
                  </div>
              </div>
              <div class="prices" @click="changeprice(999)" :class="{'active':depositprice == 999  }">
                  <div class="pricesymbol">
                    <div>$9.99</div> <div>+</div> <div>K650</div>
                  </div>
              </div>
              <div class="prices" @click="changeprice(1999)" :class="{'active':depositprice == 1999  }">
                  <div class="pricesymbol">
                    <div>$19.99</div> <div>+</div> <div>K1500</div>
                  </div>
              </div>
              <div class="prices" @click="changeprice(2999)" :class="{'active':depositprice == 2999  }">
                  <div class="pricesymbol">
                    <div>$29.99</div> <div>+</div> <div>K4000</div>
                  </div>
              </div>
            </div>
          <!--  <div class="text-center mt-10">
               <v-text-field  disabled v-model="depositpricedisplay" type="text" outlined></v-text-field>
            </div>-->
          </div> 
        </div>
        <div v-if="!depositscreenfirst">
          <div class="text-left"><a @click="depoback"><span class="material-icons">arrow_back</span></a></div>
          <div class="totalbalance"  v-bind:style="{ 'background-image': 'url(' + depostieback + ')' }">
             <div class="inneramt">
               <div class="leftside">Pack</div>
               <div class="rightside myflex"><div>{{depositpricedisplay}}</div></div>
             </div>
          </div>
          <div class="totalbalance  mt-3"  v-bind:style="{ 'background-image': 'url(' + depostieback + ')' }">
            <div class="inneramt">
               <div class="leftside">Paypal Fee</div>
               <div class="rightside myflex"><div>${{ getprentage() }}</div></div>
            </div>
          </div>
          <div class="totalbalance mt-3"  v-bind:style="{ 'background-image': 'url(' + depostieback + ')' }">
            <div class="inneramt">
              <div class="leftside">Total</div>
              <div class="rightside myflex"><div>${{gettotalvalue(depositprice)}}</div></div>
            </div>
          </div> 
        </div>
        <div v-if="error" class="red--text smallfonts">{{ errorText }}</div>
      </v-card-text>
      <v-card-actions class="justify-center action pb-5">
        <v-btn x-large class="primary" :disabled="makedisable" v-if="depositscreenfirst" @click="nextdepositscreen">Next</v-btn>
        <div v-else id="paypal-button"></div>
      </v-card-actions>
    </v-card>
   </v-dialog>
</template>
<script>
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import LoadScript from 'vue-plugin-load-script';

Vue.use(LoadScript);

Vue.use(VueAxios, axios)
export default {
  props:{
    dialogdeposit:Boolean
  } , 
  data(){
    return{
        depositscreenfirst:true,
        error :'',
        errorText:'',
        makedisable:true,
        depositprice:0,
        depositpricedisplay:'$0',
        depostieback:require("@/assets/depositeback.png"),
    }
  },
  
  methods:{
      gettotalvalue(){
         var price     = this.depositprice/100;
         var mainval   = price;
         var percent   = 0;
         var paypalfee = 2;//this.$store.getters.getcurrencySetting.paypal_fee;
         
         if(paypalfee > 0)
         {
           percent = (price/100)*paypalfee;
         }
         mainval = (parseFloat(percent)+parseFloat(mainval)).toFixed(2);
         
         this.finaldeposite = mainval;

         return mainval;
    },
    depoback(){
      this.depositscreenfirst = true;
    },
    closeDialog(){
     // this.dialogdeposit = false
      this.$emit('update:dialogdeposit', false);
      this.depositscreenfirst = true;
    },
    changeprice(price){
      
      if(price == 599)
        this.depositpricedisplay = '$5.99 + K300'; 
      else if(price == 999)
        this.depositpricedisplay = '$9.99 + K650';
      else if(price == 1999)
        this.depositpricedisplay = '$19.99 + K1500';
      else if(price == 2999)
        this.depositpricedisplay = '$29.99 + K4000'; 
      //depositpricedisplay
      this.depositprice = price;
      this.makedisable = false;
      localStorage.setItem('price', price);
    },
    getprentage()
    { 
         var mainval   = 0;
         var paypalfee = 2;//this.$store.getters.getcurrencySetting.paypal_fee;
         //same formula applied on below creat payment
         if(paypalfee > 0)
         {
           var price = this.depositprice/100;
           mainval = (price/100)*paypalfee;
         }

         return mainval.toFixed(2);
    },
    nextdepositscreen(){
      this.depositscreenfirst = false;
      this.makedisable = false;
      /*this.$store.dispatch("getpaymentToken",{"amount":this.depositprice}).then((response) => {
         this.referenceno = response.data.data.reference;
      });*/
       var mythis = this;
       this.$loadScript("https://www.paypalobjects.com/api/checkout.js").then(() => 
     {
       window.paypal.Button.render({
         env: 'sandbox',
         client: {
          sandbox: 'AYSQ-B3COkyHGMWTWkyrfWtl8tPdxEBI0GejO4tfbmPX3JbT_zv-lshM3EmQXiHoDzvd964pQGz1Sjlo',
          //production: 'AUxJGrr53OY24x1KlUwTHy4rkPvhW_Srkli1rmnRjfHyDTa00ZEgphHVJ0wkdVnWxA43KeMrPCVS39re'
         },
         locale: 'en_US',
         style: {
                  size: 'small',
                  color: 'gold',
                  shape: 'pill',
                  tagline: 'false'
                },
         commit: true,

         payment: function() {
           //console.log(resolve, reject);
          let returnUrl = "https://admin.sporttrade.online/faq";
          let amount = mythis.depositprice;
          //remove 2 percent paypal fee because in database need acutal price

           return new Promise((resolve, reject) => {
           axios.post('create-payment', {
            return_url: returnUrl,
            amount:amount
        }, {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }
            })
            .then(res => {
              console.log(res.data.data.reference_id);
                localStorage.setItem('reference_id', res.data.data.reference_id);
                resolve(res.data.data.paypal_intent_id)
            })
            .catch(error => {
                reject(error)
            })
    })
    },
    
    onAuthorize: function(data) {
      
          return new Promise((resolve, reject) => {
        axios.post('execute-payment',  {
            payer_id: data.payerID,
            payment_id: data.paymentID,
            reference_id:localStorage.getItem('reference_id')
        }, {
                headers: { 'Authorization': 'Bearer ' +  localStorage.getItem('access_token') }
            })
            .then(res => {
              localStorage.removeItem('reference_id');
              localStorage.removeItem('price');
              mythis.closeDialog();
              mythis.$store.dispatch('retrieveBalance');
                resolve(res)
            })
            .catch(error => {
                reject(error)
            })
    })
    }
  }, '#paypal-button');
     });
    },
  }     
}
</script>
<style scoped>
.pricerange::v-deep .v-application .primary.lighten-3{background-color:none;}
.pricerange::v-deep .v-slider--horizontal .v-slider__track-container{height:6px;}
.pricerange::v-deep .v-slider__thumb{height:20px; width:20px;background: white !important;background-color: white;border: 4px solid #247cdd;}
.pricebtns {display: flex;cursor:pointer;justify-content: space-between;}
.prices {background: white;border-radius: 10px;flex: 0 23%;margin-left: 1%;padding: 15px 0;}
.prices.active {background: #247CDD;border-radius: 10px;flex: 0 23%;margin-left: 1%;padding: 15px 0;}
.pricesymbol{font-size: 18px;background: white;margin: auto;padding: 10px 20px;border-radius: 10px;width:65%;color: #767676;}
.pricevalue {color: white;text-align: center;padding: 10px 0 0;font-size: 16px;color: #767676;}
.active .pricesymbol {color:#247cdd;}
.active .pricevalue {color: white;}
.popupbody { padding-top: 15px; padding-bottom: 10px;}
.prices .pricesymbol {	font-size: 15px;background: white;	margin: auto;padding: 0px 2px;border-radius: 4px;width: 95%;color: #767676;	text-align: center;}
.prices .pricevalue > div{font-size:14px;}
.prices .pricevalue{padding-top:0px;}
.theme--light.v-slider .v-slider__track-fill {
    background: #247cdd !important;
}
.popupbody .heading {
    font-size: 18px;
    font-weight: 500;
    color: #010101;
    line-height: 35px;
}
.headfirst[data-v-25047ed8] {
    color: #767676;
    font-size: 16px;
    font-weight: 400;
}
.amntdiv {
    font-size: 22px;
    color: #010101;
    font-weight: 400;
    line-height: 27.5px;
}
.totalbalance {background-size: 100% 100%;background-repeat: no-repeat;}
.inneramt{display: flex;justify-content: space-between;padding: 15px 15px;color: white;font-size: 15px;font-weight: 400;}
.name-title {text-transform: capitalize;}
.closeicon{cursor: pointer;}
</style>