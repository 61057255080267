import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

axios.defaults.baseURL = process.env.VUE_APP_BASEAPI;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
 
export default new Vuex.Store({
  state: {
    token: localStorage.getItem('access_token') || null,
    actiontype:'',
    actionmodal:false,
    userid:null,
    name: '',
    email: '',
    balance: 0,
    inbank:0,
    kels:0,
    userdetails:[],
    avatar:'',
    region:'',
    routeMeta: null,
  },
  getters: {
    loggedIn(state) {
      return state.token !== null;
    },
    getActionmodal(state){
      return state.actionmodal;
    },
    getActionType(state){
      return state.actiontype;
    },
    getUserdetails(state){
      return state.userdetails;
    },
    getAvatar(state){
      return state.avatar; 
    },
    getUserid(state){
      return state.userid;   
    },
    name(state) {
      return state.name;
    },
    email(state) {
      return state.email;
    },
    inbank(state){
      return state.inbank; 
    },
    kels(state){
      return state.kels; 
    },
    balance(state) {
      return state.balance;
    },
    isAdmin(state) {
      return state.isAdmin;
    },
    getRegion(state){
      return state.region;
    },
    getRouteMeta(state) {
      return state.routeMeta;
    },
  },
  mutations: {
    setRouteMeta(state, meta) {
      state.routeMeta = meta;
    },
    retrieveToken(state, token) {
      state.token = token;
    },
    setRegion(state,value){
      state.region = value;  
    },
    setActionModal(state,value){
      state.actionmodal = value;
    },
    setActionType(state,value){
      state.actiontype = value;
    },
    setUserid(state,payload){
      state.userid = payload; 
    },
    destroyToken(state) {
      state.token = null;
    },
    setName(state, name) {
      state.name = name;
    },
    setEmail(state, email) {
      state.email = email;
    },
    setInbank(state, balance) {
      state.inbank = balance;
    },
    setBalance(state, balance) {
      state.balance = balance;
    },
    setKels(state, kels) {
      state.kels = kels;
    },
    setAvatar(state,payload){
      state.avatar = payload;
   },
    setIsAdmin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    setUserdetails(state,payload){
      state.userdetails = payload;
   },
  },
  actions: {
    retrieveToken(context, credentials) 
    {
      return new Promise((resolve, reject) => {
        Vue.axios.post('login', {
          email: credentials.username,
          password: credentials.password,
          damnnId: credentials.damnnId
        }).then(response => {
          
          if(response.data.status)
          {
            const token = response.data.result.access_token;
            localStorage.setItem('access_token', token);
            context.commit('retrieveToken', token);
            resolve(response);
          }
          else
          {
            resolve(response);
          }
          
        }).catch(error => {
          reject(error);
        })
      })
    },
    updateUserDetails(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          var userid = context.getters.getUserid;
          Vue.axios.post('users/profileupdate/'+userid,{name:data.name}).then(response => {
            resolve(response);
            context.dispatch("retrieveUserDetails");
          }).catch(error => {
            reject(error);
          })
        })
      }         
     },
     unsubscribeplan(context)
     {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('unsubscribeplan').then(response => {
            resolve(response);
            context.dispatch("retrieveUserDetails");
          }).catch(error => {
            reject(error);
          })
        })
      }         
     },  
     updateAvtar(context,data){
      axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
     return new Promise((resolve, reject) => 
     {
      axios.post('user/avatar', data, { headers: {'Content-Type': 'multipart/form-data' }}).then(response => 
       {
         //console.log(response.data.data.filename);
           context.commit("setAvatar",response.data.data.filename);
           resolve(response);
        }).catch(error => {
         reject(error);
        })
     })
   },
     citieslist(context,data) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('getcitieslist', {
          countryname: data.countryname,
          stateid:data.stateid
         }).then(response => {
          resolve(response.data);
          //resolve(response);
        }).catch(error => {
          reject(error);
        })
     
      })
    }, 
    changePassword(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          //var userid = context.getters.getUserid;
          Vue.axios.post('users/profileupdate',{password:data.password,oldpassword:data.oldpassword}).then(response => {
            resolve(response);
            context.dispatch("retrieveUserDetails");
          }).catch(error => {
            reject(error);
          })
        })
      }         
     }, 
     
    retrieveTokenRegister(context, credentials) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('login', {
          email: credentials.username,
          password: credentials.password,
        }).then(response => {
          if (response.data.result.access_token) 
          {
            const token = response.data.result.access_token;
            localStorage.setItem('access_token', token);
            context.commit('retrieveToken', token);
            context.dispatch("retrieveUserDetails");
            resolve(response);
          } else {
            
            const error = {
              response: {
                data: {
                  message: response.data.data.errors.error
                }
              }
            }
            reject(error)
          }
        }).catch(error => {
          reject(error);
        })
      })
    },
    register(context, data) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('/user/create', {
          username   : data.username,
          firstname  : data.firstname,
          lastname   : data.lastname,
          email      : data.email,
          password   : data.password,
          region     : data.region,
          dob        : data.dob,
          gender     : data.gender,
          cityname   : data.cityname,
          suburb     : data.suburb,
          stateid    : data.stateid
        }).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },
    retrieveUserDetails(context) 
    {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('user').then(response => { 

            const name = response.data.data.name;
            const email = response.data.data.email;
            const isAdmin = response.data.role == 1 ? true : false
            
            if(typeof response.data.data.avatar != 'undefined')
            {
                context.commit('setAvatar',response.data.data.avatar);
            }
            context.commit('setUserid',response.data.data.id);
            context.commit('setName', name);
            context.commit('setEmail', email);
            context.commit('setIsAdmin', isAdmin);
            context.commit('setRegion', response.data.data.region);
            context.commit('setUserdetails',response.data.data);
            context.dispatch('retrieveBalance');
            
            resolve(response); 
            
          }).catch(error => {
            context.commit('setName', '');
            context.commit('setEmail', '');
            context.commit('setIsAdmin', false);
            reject(error);
          })
        })
      }
    },
    retrieveBalance(context) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          //var userid = context.getters.getUserid;
          
          Vue.axios.get('wallet/user-balance').then(response => {
            const balance = response.data.data.balance;
            const inbank  = response.data.data.inbank;
            const kels    = response.data.data.kels;
            context.commit('setBalance', balance);
            context.commit('setInbank', inbank);
            context.commit('setKels', kels);
            resolve(response.data);
          }).catch(error => {
            context.commit('setBalance');
            reject(error);
          })
        })
      }
    },
    subsription(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
      return new Promise((resolve, reject) => {
      
        Vue.axios.post('subscription', {
          subscription: data.pack,
         }).then(response => {
          resolve(response.data);
          //resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
     } 
    },
    stateslist(context,data) {
      return new Promise((resolve, reject) => {
        Vue.axios.post('getstateslist', {
          countryname: data.countryname,
         }).then(response => {
          resolve(response.data);
          //resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    }, 
    destroyToken(context) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.post('logout').then(response => {
            localStorage.removeItem('access_token');
            context.commit('destroyToken');
            context.commit('setUserdetails','');
            resolve(response);
          }).catch(error => {
            localStorage.removeItem('access_token');
            context.commit('destroyToken');
            reject(error);
          })
        })
      }
    },
    getcategories(context){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('categories').then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }          
    },
    checkBalance(context,data){
      return new Promise((resolve, reject) => {
        if (context.getters.loggedIn) { 
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;     
        Vue.axios.post('check-balance', { kels: data.kels, cents:data.cents}).then(response => 
        {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
        }
      })              
    },
    submitQuestion(context,data)
    {
      return new Promise((resolve, reject) => {
        if (context.getters.loggedIn) {  
        Vue.axios.post('question', { data:data}).then(response => 
        {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
       }
      })         
    },
    getQuestions(context,data){
      return new Promise((resolve, reject) => 
      {
        if (context.getters.loggedIn) 
        {  
          Vue.axios.post('get-questions', { data:data }).then(response => 
          {
              resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        }
        else
        {
          Vue.axios.post('get-questions-nolog', { data:data }).then(response => 
            {
                resolve(response.data);
            }).catch(error => {
              reject(error);
            })
        }
      })         
    },
    getQuestion(context,data)
    {
      return new Promise((resolve, reject) => 
      {
        if (context.getters.loggedIn) 
        {  
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;  
          Vue.axios.get('get-question/'+data.id).then(response => 
          {
              resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        } 
        else
        {
          Vue.axios.get('get-question-nolog/'+data.id).then(response => 
            {
                resolve(response.data);
            }).catch(error => {
              reject(error);
            })
        }
      })         
    },
    getDashboardData(context)
    {
      return new Promise((resolve, reject) => 
      {
        if (context.getters.loggedIn) 
        {   
          Vue.axios.get('dashboard/').then(response => 
          {
              resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        } 
      })         
    },
    getLatestQuestions(context,data)
    {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('get-latest-questions/'+data.type).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }                
    },
    getLatestQstHome(context)
    {
      if (context.getters.loggedIn) 
      {  
        return new Promise((resolve, reject) => {
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;   
          Vue.axios.get('get-latest-question-home').then(response => 
            {
                resolve(response.data);
            }).catch(error => {
              reject(error);
            })
         })   
      }    
      else
      {
        return new Promise((resolve, reject) => {
        Vue.axios.get('get-latest-question-home-nologged').then(response => 
          {
              resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        });  
      }
    },
    getAttemptQuestion(context)
    {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('question-attempt').then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }          
    },
    getSubcategory(context,data)
    {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('get-subcategory/'+data.category).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }                
    },
    updateAnswer(context,data)
    {
      return new Promise((resolve, reject) => {
        if (context.getters.loggedIn) 
        { 
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;     
          Vue.axios.post('update-answer', { question: data.question, answer:data.answer}).then(response => 
          {
             resolve(response.data);
          }).catch(error => {
             reject(error);
          })
        }
      })            
    },
    answer(context,data)
    {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.post('answer',{ data:data }).then(response => 
            {
                resolve(response.data);
            }).catch(error => {
              reject(error);
            })
        })
      }            
    },
    getallTransctiondata(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('/user/transcaction-data/?page='+data.page).then(response => {
            resolve(response);
          }).catch(error => {
            reject(error);
          })
        })
      }         
    },
    getData(context,data)
    {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.post('/get-data',{id:data.id,reason:data.reason}).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }                
    },
    checkElection(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.post('/check-election',{url:data.url}).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }               
    },
    saveElection(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.post('/save-election-data', {voters:data.file}, { headers: {'Content-Type': 'multipart/form-data' }}).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }               
    },
    getmyTemplates(context){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('/get-my-templates').then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }         
    },
    checkManager(context){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('/check-election').then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }         
    },
    getmyelections(context){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('/get-my-elections').then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }         
    },
    deleteTemplate(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('/delete-template/'+data.id).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }         
    },
    getElectionsData(context,data){
      return new Promise((resolve, reject) => {
        Vue.axios.post('/get-elections',{data:data}).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    },
    getElectionFields(context,data){
      return new Promise((resolve, reject) => {
        Vue.axios.get('/get-election-fields/'+data.eid).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    },
    getElectionData(context,data){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + context.state.token;
      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          Vue.axios.get('/get-election-data/'+data.eid).then(response => {
            resolve(response.data);
          }).catch(error => {
            reject(error);
          })
        })
      }         
    },
    getOtp(context,data){
      return new Promise((resolve, reject) => {
        Vue.axios.post('/get-otp',{data:data}).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    },
    submitOtp(context,data){
      return new Promise((resolve, reject) => {
        Vue.axios.post('/submit-otp',{data:data}).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        })
      })
    },
  },
  modules: {
  }
})
