<template>
 <div class="fill-height">
    <div class="container mt-10 mb-10 align-items-center mobdiff">
     <div class="actiontop">
      <v-btn v-if="loggedIn" class="potamnt" color="primary" @click="$router.go(-1)">
        <span class="material-icons">arrow_back_ios</span> Back</v-btn>
     </div> 
      
     <div class="dashboard">
       <div class="showamount">
         <div class="items"><div class="makeitbtn"><span>${{ amount }}</span> <span>K{{ kels }}</span></div></div>
         <div class="items">
           <v-btn class="potamnt" color="secondary"><span>${{ potamount }}</span> <span>K{{ potkels }}</span></v-btn>
         </div>
       </div>
       <h2 class="question">Q. {{ question }}</h2>
       <div class="question-area">
         <v-radio-group class="ansgroup"  v-model="answer" >
          <v-radio color="secondary" :disabled="disabled"  v-for="(val,key) in options" :key="key"  :value="key">
            <template v-slot:label>
              <div class="makefull">
                <div>{{ val }} </div>
                <div v-if="timedout == 1">
                  <v-progress-linear 
                      :color=percentagecol[key]
                      rounded
                      :value=percentage[key]
                      height="15">
                      <span class="progress-bar-txt">{{ percentage[key] }}%</span>
                  </v-progress-linear>
                </div>
              </div>
            </template>
          </v-radio>
         </v-radio-group>
       </div>
       <div class="text-center">
        <v-btn v-if="own == 0 && loggedIn && timedout == 0" @click="confirm()" color="primary">Confirm</v-btn>
        <v-btn to="/auth" v-if="own == 0 && !loggedIn" color="primary">Login to answer</v-btn>
        <div v-if="own == 1">You cant answer to this question</div>
       </div>
     </div>
   </div>    
   <v-dialog transition="dialog-bottom-transition" max-width="300" v-model="dialog">
          <v-card>
            <v-toolbar color="primary" dark>Alert</v-toolbar>
            <v-card-text>
              <div class="mt-5 message">{{ message }}</div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="closedialog()">Close</v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>   
   <Loading :displayDialog="showdialog" />
 </div>
</template>
<script>
import Loading from '../components/Loader.vue';

export default {
    name: 'Question',
    components:{
      Loading
    },
    data()
    {
      return{
        showdialog:false,
        questionid:0,
        question:'',
        options:[],
        answer:'',
        kels:0,
        amount:0,
        potkels:0,
        potamount:0,
        own:1,
        dialog:false,
        message:'',
        showdeposite:false,
        timedout:0,
        disabled:false,
        percentage:[],
        percentagecol:[]
      }
    },
    computed:{
      loggedIn() {
        return this.$store.getters.loggedIn;
     },
    },
    created(){
      this.showdialog = true;
      if(typeof this.$route.params.id == "undefined")
      {
        this.$router.push({ name: "home" });
      }
      else
      {
        this.questionid = this.$route.params.id; 
        this.getQuestion();
      }
    },
    methods:{
      closedialog(){
        this.dialog = false;
        this.message = '';
        if(this.showdeposite)
        {
          this.$root.$refs.Master.opendeposit();
        }
      },
      getQuestion(){
         this.$store.dispatch("getQuestion",{id:this.questionid}).then((response) => {
            this.showdialog=false;
            if(response.status)
            {
               this.question  = response.data.question;
               this.options   = response.data.options;
               this.kels      = response.data.kels;
               this.amount    = response.data.amount;
               this.potkels   = response.data.potkels;
               this.potamount = response.data.potamount;
               this.own       = response.data.own;
               this.timedout  = response.data.timedout;
               this.percentage = response.data.percentage;
               this.percentagecol = response.data.percentagecol

               if(this.timedout == 1)
               {
                  this.disabled = true;
               }
               if(response.data.choice != '')
               {
                 this.answer = response.data.choice;
                 
               }
            }
         })
      },
      confirm()
      {
        this.showdialog = true;
        this.$store.dispatch("answer",{question:this.questionid,answer:this.answer}).then((response) => 
        {
           if(!response.status)
           {
              this.message = response.message;
              this.dialog = true;
              
              if(response.data == "low balance")
              {
                this.showdeposite = true;
              }
              this.showdialog = false;
           }
           else
           {
              this.showdialog = false;
              this.message = response.message;
              this.dialog = true;
              this.showdeposite = false;
              this.$store.dispatch("retrieveBalance");
              setTimeout(() => this.$router.go(-1), 2000);
           }
        });
      }
    }
}    
</script>
<style scoped>
.question{font-family: 'Poppins', sans-serif; font-size: 18px;}
.showamount{width: 98%;}
.showamount div.items {width: 12%;}
.actiontop{width: 80%; margin: auto; margin-bottom: 15px;}
@media(max-width:1180px)
{
  .showamount div.items{width: 15%;}
}  

@media(max-width:820px)
{
  .showamount div.items{width: 16%;}
  
}  
@media(max-width:768px)
{
  .showamount div.items{width: 13%;}
  .actiontop{width: 100%;}
}  
@media(max-width:740px)
{
  .showamount div.items{width: 15%;}
} 
@media(max-width:680px)
{
  .showamount div.items{width: 17%;}
}  
@media(max-width:480px)
{
  .filter{display: block;}
  .filter div{margin-bottom: 10px;}
  .showamount{margin-bottom:10px}
  .showamount div.items { width: 30%;}
} 
@media(max-width:400px)
{
  .showamount div.items{ width: 30%;}
}  
@media(max-width:320px)
{
  .showamount div.items{ width: 40%;}
}   
</style>
<style>
.ansgroup .theme--light.v-label { color: black;font-size: 14px; font-family: 'Poppins', sans-serif;}
.v-dialog > .v-card > .v-card__text {
    padding: 0 15px 15px;
}
.makefull{width: 100%; align-items: center; display: flex;}
.makefull > div:nth-child(1){min-width: 15%}
.makefull > div:nth-child(2){width: 46%;    margin-left: 15px;}
.progress-bar-txt{font-weight: bold; color: black;}
</style>
