<template>
  <div>
    <v-data-table :headers="headers" :items="itemsWithSno" :single-expand="singleExpand" :expanded.sync="expanded"
      item-key="uniqid" show-expand  class="elevation-1 buysellmob" hide-default-footer fixed-header
      :height="450" disable-pagination>
      <template v-slot:item="{ item, expand, isExpanded,index }">
        <tr>
          <td>{{ item.date }}</td>
          <td>{{ item.name }}</td>
          <td>
            <div class="countdown history" v-if="item.enddate != ''">
              <flip-countdown :deadline=item.enddate></flip-countdown>
            </div>
          </td>
          <td>
            <div class="countdown history" v-if="item.ansdate != ''">
              <flip-countdown :deadline=item.ansdate></flip-countdown>
            </div>
          </td>
          <td>{{ item.type }}</td>
          <td><span :class="{ 'redcolor': item.type == 'Debit', 'greencolor': item.type == 'Credit' }">${{ item.deposite }}</span></td>
          <td><span :class="{ 'redcolor': item.type == 'Debit', 'greencolor': item.type == 'Credit' }">K{{ item.kels }}</span></td>
          <td>
            <v-btn 
              v-if="item.showbutton == 1"
              color="themebtn" 
              @click="expand(!isExpanded); getUnitsData(item,isExpanded,index)" 
              class="secondary">{{ item.buttonlabel }}</v-btn> <!--getUnitsData(item,isExpanded)-->
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers,item, index }">
        <td :colspan="headers.length" class="customtd">
          <div class="dashboard mt-3 mb-3">
            <div class="actbtnsdash">
              <div>Q. {{ item.questiondata.question }} </div>
            </div>
            <div class="options">
              <v-radio-group class="ansgroup"  
                @change="updateanswer(item.transid,index)" 
                :disabled="item.questiondata.disabled"
                v-model="answer[index]"
                >
                <v-radio v-for="(vals,keys) in item.questiondata.options" :key="keys" color="secondary" :value="keys">
                  <template v-slot:label>
                    <div class="makefull">
                      <div :class=vals.color>{{ vals.value }}</div>
                      <div v-if="item.questiondata.timedout == 1">
                        <v-progress-linear 
                        :color=item.questiondata.percentagecol[keys]
                        rounded
                        :value=item.questiondata.percentage[keys]
                        height="15">
                        <span class="progress-bar-txt">
                        {{ item.questiondata.percentage[keys] }}%
                        </span>
                       </v-progress-linear>
                      </div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
          </div>
        </td>
      </template>
    </v-data-table>
    <v-dialog transition="dialog-bottom-transition" max-width="300" v-model="confirmdialog">
      <v-card>
        <v-toolbar color="primary" dark>Alert</v-toolbar>
        <v-card-text>
          <div class="mt-5 message">You want to confirm this option ?</div>
        </v-card-text>
        <v-card-actions class="justify-center mt-3">
          <v-btn color="green" class="white--text" @click="updateAnswerOption()">Yes</v-btn>
          <v-btn color="red"   class="white--text" @click="canceloption()">No</v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>  
  </div>
</template>
<script>
import FlipCountdown from 'vue2-flip-countdown'
export default {
  name: 'Userquestion',
  data() {
    return {
      desserts: [],
      expanded: [],
      answer:[],
      page: 1,
      singleExpand: false,
      confirmdialog:false,
      lastqtnid:'',
      lastkey:'',
    }
  },
  components: {
    FlipCountdown
  },
  computed: {
    headers() {
      let headers = [
        { text: "Date", value: "" },
        { text: "Reference", value: "" },
        { text: "End Date", value: "", class: "text-center-custom" },
        { text: "Answer Date", value: "", class: "text-center-custom" },
        { text: "Type", value: "" },
        { text: "$", value: "" },
        { text: "Kels", value: "" },
        { text: "", value: "data-table-expand" },
      ]
      return headers
    },
    itemsWithSno() {
      return this.desserts.map((d, index) => ({ ...d, sno: index + 1, keyval: index }))
    },
  },
  mounted() {
    this.getHistory();
  },
  methods: {
    getHistory($state) {
      this.$store.dispatch("getallTransctiondata", { page: this.page }).then((result) => {
        if (result.data.data.length > 0) {
          this.desserts.push(...result.data.data);
          this.page += 1;
          $state.loaded();
        }
        else {
          $state.complete();
        }
        this.overlay = false;
      });
    },
     getUnitsData(item, isExpanded,index) {
       if (!isExpanded) {
         var reason = item.showfor;
         var id     = item.transid;
         this.$store.dispatch("getData",{id:id,reason:reason}).then((result) => {
             //item.questiondata = result.data;
             this.desserts[index].questiondata = result.data;
             if(result.data.answered == 1)
             {
               if(reason == 'answer-show')
               {
                this.answer[index] = result.data.choosed;
               }
               else if(reason == 'question-show')
               {
                this.answer[index] = result.data.answer; 
               }
             } 
         });
       }
     },
     updateanswer(id,lastkey){
        this.lastqtnid = id;
        this.lastkey   = lastkey;
        this.confirmdialog = true;
      },
      canceloption(){
         delete this.answer[this.lastkey];
         this.confirmdialog = false;
      },
      updateAnswerOption(){
        this.desserts[this.lastkey].questiondata.disabled = true;
        this.confirmdialog = false;
        this.$store.dispatch("updateAnswer",{question:this.lastqtnid,answer:this.answer[this.lastkey]}).then(() => {
            //console.log(response);
        });
      },
  }
}       
</script>
<style >
.redcolor {
  color: red;
}

.greencolor {
  color: green;
}

th.text-start.text-center-custom {
  text-align: center !important;
}

.countdown.history .flip-card {
  font-size: 0.9rem !important;
}

.countdown.history .flip-clock__slot {
  font-size: 8px !important;
}

.customtd {
  box-shadow: inset 0px 4px 4px -5px rgba(50, 50, 50, 0.75), inset 0px -4px 4px -5px rgba(50, 50, 50, 0.75);
}</style>