<template>
 <v-container class="fill-height" fluid>
  <v-row align="center" justify="center">
   <v-col cols="12" sm="6" md="6" >
    <v-card class="customround">
     <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="8" class="text-center pt-5 pb-5">
       <v-form action="#" @submit.prevent="update">
        <v-card-text class="removespace">
         <span v-if="error"><strong class="red--text">{{errorText}}</strong></span>
          <v-row>
           <v-col><v-avatar size="100px"><img alt="Avatar" :src="image"></v-avatar></v-col>
          </v-row>
          <v-row>
           <v-col>
              <span @click="showuploadsection" class="material-icons">photo_camera</span>
           </v-col>
          </v-row>
          <v-card flat v-if="showupload">
            <v-row>
              <v-col>
                 <input type="file" class="primary" id="files" ref="files" multiple v-on:change="handleFilesUpload()"/>
                 <div class="red--text" v-if="avatarerror">{{ avatarerror }}</div>
              </v-col>                  
            </v-row>
            <v-row>
              <v-col><v-btn class="mr-4 " type="button" color="primary"  v-on:click="submitFiles()">Upload</v-btn></v-col>
            </v-row>  
          </v-card>
         
          <v-text-field label="User Name" name="name"  :rules="usernameRules" outlined type="text" id="username" v-model="username"/>
          <v-text-field label="Email" name="email" readonly outlined type="text"  v-model="email"/>
        </v-card-text>
        <v-card-actions class="justify-center">
              <v-row dense>
                 <v-col col="12" md="12" sm="12">  
                   <v-btn type="submit" class="button__full x-large" color="primary">Update Information</v-btn>
                 </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
            </v-col> 
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>  
  </v-container>
</template>
<script>
 export default{
   name:"personal",
   data(){
     return {
        error:'',
        text:'',
        errorText:'',
        usernameval:'',
        snackbar: false,
        timeout: 2000,
        showupload:false,
        avatarerror:'',
        files:[],
          usernameRules: [
        v => !!v || "Please enter user name",
        
      ],
     };   
   },  
   computed:{
      image () {
         var avatar = require("@/assets/avatar.png");
         if(this.$store.getters.getAvatar != '') 
        {
            avatar = this.$store.getters.getAvatar;
        } 
         return avatar;
      }, 
      username: {
            get () {
                return this.$store.getters.name;
            },
            set (val) {
                this.usernameval = val            
            }
        },
      email() {
          return this.$store.getters.email;
      }
   },
   created(){
        //  this.$store.dispatch("retrieveUserDetails").then((response) => {
        //     this.username = response.data.data.name;
        //     this.email = response.data.data.email;
        //  });
   },
   methods: {
      update(){
        console.log(this.usernameval);
          this.$store.dispatch("updateUserDetails",{"name":this.usernameval}).then(() => {
             this.snackbar = true;
             this.text = "Profile Information Updated"
          });
      },
      showuploadsection(){
         this.showupload =! this.showupload;
      },
      handleFilesUpload(){
        let uploadedFiles = this.$refs.files.files[0];
        const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif']; // Add other image formats if needed
        const fileExtension = uploadedFiles.name.split('.').pop().toLowerCase(); // Get file extension
        this.avatarerror = '';
         if (!allowedExtensions.includes(fileExtension)) {
           this.avatarerror = 'Please upload only image files (jpg, jpeg, png, gif)';
           return;
         }
        this.files = uploadedFiles;
       
        // for( var i = 0; i < uploadedFiles.length; i++ ){
        //   this.files.push( uploadedFiles[i] );
        // }
      },
      submitFiles(){
        let formData = new FormData();
        let file = this.files;
       
        if(file != '')  
        {
          formData.append('avatar', file);
        }  
        
        this.$store.dispatch("updateAvtar",formData).then(() => {
          this.files = [];
          this.showuploadsection();
        }); 
      },
   } 
 }
</script>
<style scoped>
.row {margin-left: 0px;}
.customround.v-card:not(.v-sheet--tile):not(.v-card--shaped){border-radius:20px;}
.button__full{width:98%;height:45px !important;}
.removespace{padding-bottom:0;}
</style>